import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import IconButton from '../../IconButton';
import PrimaryButton from '../../PrimaryButton';
import CheckIcon from '../../../../public/assets/images/theme-2/icons/handin-hover.svg';
import { ViewSection } from '../styles';
import { SubmitPanel } from '../../NodeContentPreview/styles';

const ChunkContentViewer = ({ isEditable, report, saveReport, isTeacher, theme }) => {
  const handinReport = () => {
    saveReport({ ...report, state: 'done' });
  };

  const resetReport = () => {
    saveReport({ ...report, state: 'active' });
  };

  if (!report && !isTeacher) {
    return null;
  }

  return (
    <>
      <ViewSection />
      <SubmitPanel>
        {isEditable ? (
          <IconButton
            icon={CheckIcon}
            onClick={handinReport}
            disabled={isTeacher}
            backgroundColor={theme.colors.buttonGreenFront}
          >
            {i18n.__('app_done')}
          </IconButton>
        ) : (
          <PrimaryButton onClick={resetReport} text={i18n.__('app_reset')} className="reset" />
        )}
      </SubmitPanel>
    </>
  );
};

export default withTheme(ChunkContentViewer);

ChunkContentViewer.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  report: PropTypes.object,
  saveReport: PropTypes.func.isRequired,
  isTeacher: PropTypes.bool.isRequired,
};
