import React from 'react';
import PropTypes from 'prop-types';
import CommentsIcon from '../../../public/assets/images/theme-2/icons/asterisk-white.svg';

import { HeaderSection, TitleText, CommentsLink, NodeToolbar } from './styles';

const NodeContentHeaderSection = ({ title, toggleComments }) => {
  const headerSection = (
    <HeaderSection>
      <TitleText>{title}</TitleText>
      <NodeToolbar>
        {toggleComments && (
          <CommentsLink href="#" onClick={toggleComments} src={CommentsIcon}>
            {i18n.__('app_discuss')}
          </CommentsLink>
        )}
      </NodeToolbar>
    </HeaderSection>
  );

  return headerSection;
};

export default NodeContentHeaderSection;

NodeContentHeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  toggleComments: PropTypes.func,
};
