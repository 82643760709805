import React from 'react';
import PropTypes from 'prop-types';

import { QuestionHeaderRow, EditorHeaderRowLeft, EditorHeaderRowRight, QuestionNumber, QuestionBody } from '../styles';

const WrittenQuizEditorHeader = ({ index = 0, headerPanel, headerRightPanel, headerText }) => {
  return (
    <QuestionHeaderRow>
      <EditorHeaderRowLeft isQuestion={true}>
        <QuestionNumber>{index + 1}</QuestionNumber>
        {headerText ? (
          <QuestionBody dangerouslySetInnerHTML={{ __html: headerText || '' }} />
        ) : (
          <QuestionBody>{headerPanel}</QuestionBody>
        )}
      </EditorHeaderRowLeft>
      <EditorHeaderRowRight isQuestion={true}>{headerRightPanel}</EditorHeaderRowRight>
    </QuestionHeaderRow>
  );
};

export default WrittenQuizEditorHeader;

WrittenQuizEditorHeader.propTypes = {
  index: PropTypes.number,
  headerPanel: PropTypes.object,
  headerRightPanel: PropTypes.object,
  headerText: PropTypes.string,
};
