import colorPalette from './colors';

const formattingTools = [
  'bold',
  'italic',
  { list: 'ordered' },
  { list: 'bullet' },
  { textsize: ['small', false, 'large', 'huge'] },
  { align: [] },
  { color: colorPalette },
  { background: colorPalette },
];

const insertTools = [
  'link',
  'divider',
  'emoji',
  'formula',
  'image',
  { insert: ['attachment', 'dropbox', 'gdrive', 'onedrive'] },
];

const recordingTools = window.editor ? ['video', 'audio', 'screen_recorder', 'canvas'] : ['canvas'];

const undoTools = ['clean', 'undo'];

export const nodeToolbarButtons = [formattingTools, insertTools, recordingTools, undoTools];

export const hubToolbarButtons = [formattingTools, undoTools, insertTools, recordingTools];
