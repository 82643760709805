import styled from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const PreviewSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 14px;
  padding: 0 42px;
  margin-top: 5px;
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const Question = styled.div`
  display: flex;
  margin-top: 20px;

  > div {
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const SubmitPanel = styled.div`
  align-items: center;
  height: 60px;
  justify-content: center;
  padding: 0 30px;
  background: ${({ theme }) => theme.colors.white};
  bottom: 80px;
  border-top: 1px solid ${({ theme }) => theme.colors.black_1};
  position: sticky;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-top: auto;

  button:not(.reset) {
    font-weight: bold;
    border: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
    background-color: ${({ theme }) => theme.colors.buttonGreenFront};
    color: ${({ theme }) => theme.colors.black};

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      border-color: ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.buttonGreenFront};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChecklistWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.behaviorBlue};
  border-radius: 11px;
  margin-bottom: 24px;
`;

export const ChecklistHeader = styled.div`
  height: 58px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  background-color: ${({ theme }) => theme.colors.behaviorBlue};
  display: flex;
  align-items: center;

  h2 {
    margin: 0px 0px 0px 19px;
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
  }
`;

export const ChecklistBody = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 5px;
  }
`;
