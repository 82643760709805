import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../../app/assets/js/shared/utils';
import WrittenQuizNodeActivityEditor from '../../NodeActivityEditor/nodes/WrittenQuizNodeActivityEditor';
import RemoveIcon from '../../../../public/assets/images/editor/delete-icon-black.svg';
import MoveUpIcon from '../../../icons/move-up-black.svg';
import UploadImageIcon from '../../../icons/upload-image.svg';
import {
  ContentSection,
  Questions,
  QuestionWrapper,
  Question,
  QuestionTools,
  DeleteQuestion,
  MoveUpQuestion,
  MoveDownQuestion,
  InsertImage,
} from '../styles';

const WrittenQuizContentEditor = ({
  children,
  questions,
  setQuestions,
  deletions,
  setDeletions,
  type,
  panelType,
  fileService,
  loading,
}) => {
  useEffect(() => {
    const lastQuestionEditor = Array.from(document.querySelectorAll('.ql-editor')).pop();
    const newQuestion = questions.find((question) => !question.id);
    if (newQuestion && lastQuestionEditor) {
      lastQuestionEditor.scrollIntoView();
    }
  }, [questions]);

  const isDisabled = (index, delta) => (index === 0 && delta === -1) || (index === questions.length - 1 && delta === 1);

  const removeQuestion = (questionId) => {
    if (questions.length === 1) {
      return;
    }
    const newDeletions = [...deletions];
    newDeletions.push(questionId);
    setDeletions(newDeletions);
    const newQuestions = questions
      .filter((question) => question.id !== questionId)
      .map((question, index) => ({ ...question, order: index }));
    setQuestions(newQuestions);
  };

  const orderQuestion = (index, delta) => {
    if (isDisabled(index, delta)) {
      return;
    }
    const newQuestions = [...questions];
    newQuestions[index].order = index + delta;
    newQuestions[index + delta].order = index;
    setQuestions(newQuestions);
  };

  const uploadImage = (questionId) => {
    const fileUpload = document.querySelector(`#file_question_editor_${questionId}`);
    fileUpload.click();
  };

  const sortByOrder = (a, b) => a.order - b.order;

  const onChange = (value, index) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...questions[index], body: value };
    setQuestions(newQuestions);
  };

  const mappedQuestions = questions.sort(sortByOrder).map((question, index) => (
    <QuestionWrapper key={`question_${question.id ? question.id : Utils.generateElementID()}`}>
      <Question>
        <WrittenQuizNodeActivityEditor
          question={question}
          index={index}
          onChange={onChange}
          type={type}
          panelType={panelType}
          fileService={fileService}
          loading={loading}
        />
      </Question>
      <QuestionTools>
        <InsertImage
          src={UploadImageIcon}
          title={i18n.__('toolbar_button_tooltip_image')}
          onClick={() => uploadImage(question.id)}
          disabled={!question.id}
        />
        <DeleteQuestion
          src={RemoveIcon}
          title={i18n.__('app_delete')}
          onClick={() => removeQuestion(question.id)}
          disabled={!question.id || questions.length === 1}
        />
        <MoveUpQuestion
          src={MoveUpIcon}
          title={i18n.__('app_move_up')}
          onClick={() => orderQuestion(index, -1)}
          disabled={!question.id || isDisabled(index, -1)}
        />
        <MoveDownQuestion
          src={MoveUpIcon}
          title={i18n.__('app_move_down')}
          onClick={() => orderQuestion(index, 1)}
          disabled={!question.id || isDisabled(index, +1)}
        />
      </QuestionTools>
    </QuestionWrapper>
  ));

  return (
    <ContentSection>
      {children}
      <Questions>{mappedQuestions}</Questions>
    </ContentSection>
  );
};

export default WrittenQuizContentEditor;

WrittenQuizContentEditor.propTypes = {
  children: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  panelType: PropTypes.number.isRequired,
  fileService: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
