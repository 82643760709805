import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../IconButton';
import CheckIcon from '../../../../public/assets/images/theme-2/icons/handin-hover.svg';
import { PreviewSection, SubmitPanel } from '../styles';
import { PreviewText } from '../../NodeActivityEditor/styles';

const ChunkContentPreview = ({ previewText }) => {
  return (
    <>
      <PreviewSection>
        <PreviewText dangerouslySetInnerHTML={{ __html: previewText }} />
      </PreviewSection>
      <SubmitPanel>
        <IconButton icon={CheckIcon}>{i18n.__('app_done')}</IconButton>
      </SubmitPanel>
    </>
  );
};

export default ChunkContentPreview;

ChunkContentPreview.propTypes = {
  previewText: PropTypes.string.isRequired,
};
