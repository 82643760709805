import React from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../ContentEditor';
import NodeActivityEditor from '../../NodeActivityEditor';

const WrittenQuizNodeActivityEditor = ({ question, index, onChange, type, panelType, fileService, loading }) => {
  const headerPanel = (
    <ContentEditor
      id={`question_editor_${question.id || index}`}
      type={type}
      panelType={panelType}
      aria-label={i18n.__('placeholder_description')}
      placeholder={i18n.__('placeholder_description')}
      value={question.body || ''}
      onChange={(value) => {
        !loading && onChange(value, index);
      }}
      fileService={fileService}
      canToggleToolbar={false}
      showToolbar={true}
      theme="bubble"
      toolbar={[['bold', 'italic', 'underline'], ['image']]}
      className="question"
    />
  );

  return (
    <NodeActivityEditor
      index={index}
      type={type}
      headerPanel={headerPanel}
      editorText={i18n.__('written_quiz_student_input_placeholder')}
      className="question"
    />
  );
};

export default WrittenQuizNodeActivityEditor;

WrittenQuizNodeActivityEditor.propTypes = {
  question: PropTypes.object.isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  panelType: PropTypes.number.isRequired,
  fileService: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
