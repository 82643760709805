import styled, { css } from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const HeaderSection = styled.section`
  margin: 0px 0px 26px 0px;
  padding: 0 20px;

  @media ${({ theme }) => theme.sizes.tablet} {
    padding: 0px 42px;
  }
`;

export const HeaderText = styled.textarea`
  color: ${({ theme }) => theme.colors.black_9};
  background-color: ${({ theme }) => theme.colors.transparent};
  font-size: 36px;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  resize: none;
  width: 100%;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
    font-style: italic;
    text-indent: -4px;
  }
`;

export const ContentSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 14px;
  padding: 0 20px;

  @media ${({ theme }) => theme.sizes.tablet} {
    padding: 0px 42px;
  }
`;

export const FooterSection = styled.section`
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.black_1};
  position: sticky;
  background: ${({ theme }) => theme.colors.white};
  z-index: 2;
  bottom: 0;
`;

export const BottomPanel = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.black_7};
  display: flex;
  height: 60px;
  margin-top: auto;
  padding: 0 30px;
  justify-content: space-between;

  .save-button {
    color: ${({ theme }) => theme.colors.black};
    transition: background-color 0.2s ease;
    margin-left: 20px;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.buttonGreenFront};
      transition: background-color 0.2s ease;
    }
  }

  .add-question-button {
    transition: border-color 0.2s ease;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.black};
      transition: border-color 0.2s ease;
    }
  }
`;

export const InfoPanel = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
  border-top: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
  color: ${({ theme }) => theme.colors.black_7};
  cursor: pointer;
  font-size: 14px;
  font-style: italic;
  padding: 16px 42px;
`;

export const InfoText = styled.div``;

export const InfoIcon = styled.button`
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.transparent};
  cursor: pointer;
  height: 32px;
  position: absolute;
  right: 50%;
  width: 32px;
  transform: scale(1);
  transition: transform 0.2s ease;

  &:hover,
  &:focus {
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

const IconButton = styled.button`
  position: relative;
  border: none;
  background-color: ${({ theme }) => theme.colors.transparent};
  outline: none;
  margin: 0;
  padding: 0px 0px 0px 20px;
  color: ${({ theme }) => theme.colors.black};
  transition: color 0.2s ease;
  color: var(--black);
  opacity: 0.7;
  transition: opacity 0.2s ease;

  &:after {
    width: 15px;
    height: 100%;
    position: absolute;
    opacity: 0.7;
    left: 0;
    top: 0px;
    content: ' ';
    background-repeat: no-repeat;
    background-position: center left;
    transition: opacity 0.2s ease;

    ${({ src }) =>
      src &&
      css`
        background-image: url(${src});
      `}
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    opacity: 1;
    transition: opacity 0.2s ease;
    &:after {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }
`;

export const DeleteIcon = styled(IconButton)``;

export const CopyIcon = styled(IconButton)`
  margin: 0px 0px 0px 15px;
`;

export const TypeIcon = styled(IconButton)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const QuestionWrapper = styled.div`
  display: flex;
`;

export const Question = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;

  > div {
    width: 100%;
  }

  .ql-editor {
    line-height: 22px;
  }
`;

export const QuestionTools = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const DeleteQuestion = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.transparent};
  padding: 13px;
  margin-bottom: 5px;
  opacity: 0.5;
  transition: 0.2s opacity ease;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    opacity: 0.7;
    transition: 0.2s opacity ease;
  }

  &[disabled] {
    opacity: 0.3;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const MoveUpQuestion = styled(DeleteQuestion)`
  border: none;
  margin-bottom: 3px;
`;

export const MoveDownQuestion = styled(MoveUpQuestion)`
  transform: rotate(180deg);
`;

export const InsertImage = styled(MoveUpQuestion)`
  background-size: 26px 26px;
  margin-bottom: 5px;
`;
