import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ContentEditor from '../ContentEditor';
import NodeContentTopSection from '../NodeContentTopSection';
import Popup from '../Popup';
import PopupMenu from '../PopupMenu';
import useApi from '../../hooks/useApi';
import RemoveIcon from '../../../public/assets/images/editor/delete-icon-black.svg';
import CopyNodeIcon from '../../../public/assets/images/icons/copy-to-clipboard-black.svg';
import InfoIconInactive from '../../../public/assets/images/icons/info-green.svg';
import InfoIconActive from '../../../public/assets/images/icons/info-white.svg';
import WrittenQuizContentEditor from './nodes/WrittenQuizContentEditor';
import { debounce, setStatusSaving, setStatusSaved, setStatusSaveFailed, getAutoSaveInterval } from '../../utils';

import {
  PanelWrapper,
  HeaderSection,
  HeaderText,
  ContentSection,
  FooterSection,
  BottomPanel,
  InfoPanel,
  InfoText,
  InfoIcon,
  DeleteIcon,
  CopyIcon,
  TypeIcon,
  Wrapper,
} from './styles';

const NodeContentEditor = ({ nodeId, editor, panel, fileService, flash }) => {
  const { options, useAutoSave, contentEditor, graph } = editor;
  const { type: baseType, updateNode } = contentEditor;
  const { userToken, integrations } = options;

  const { getData, loading } = useApi(userToken);
  const { postData } = useApi(userToken);
  const [updatedAt, setUpdatedAt] = useState();
  const [isInfoShowing, setIsInfoShowing] = useState(false);
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [data, setData] = useState();
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [deletions, setDeletions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(true);
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(!useAutoSave);
  const [confirmPopupIsOpen, setConfirmPopupIsOpen] = useState(false);
  const [showTypeSelector, setShowTypeSelector] = useState(false);

  const getArticleTypeIcon = (articleType) => `/assets/images/theme-2/icons/${articleType}.svg`;
  const nodeDescription = i18n.__(`${baseType}_node_description`);
  const isArticle = baseType === 'chunk';

  useEffect(() => {
    const getNodeData = async () => {
      if (!loading) {
        const nodeType = isArticle ? 'chunks' : 'behaviors';
        const response = await getData(`/api/v3/editor/${nodeType}/${nodeId}`);
        if (response.success) {
          const body = response.body;
          const loadedQuestions = body.data.QuizQuestions;
          setTitle(body.name || '');
          setType(body.type || '');
          setDescription((isArticle ? body.data.content : body.data.body) || '');
          setImage(body.image);
          setUpdatedAt(body.updated_at);
          setQuizQuestions(
            loadedQuestions && loadedQuestions.length === 0 && !body.name
              ? [{ body: '', order: 0 }]
              : loadedQuestions || [],
          );
          setData(body.data);
        }
      }
    };
    getNodeData();
  }, []);

  useEffect(() => {
    if (data) {
      setIsUpdating(false);
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener('useAutoSave', handleAutoSave);
    return () => document.removeEventListener('useAutoSave', handleAutoSave);
  }, []);

  const setupAutogrow = () => {
    const element = document.querySelector('.autogrow');
    if (element) {
      setTimeout(() => {
        Autogrow(element);
      }, 100);
    }
  };

  useEffect(() => {
    if (!loading) {
      setupAutogrow();
    }
  }, [loading]);

  useEffect(() => {
    document.addEventListener('toggleDyslexicFont', setupAutogrow);
    return () => document.removeEventListener('toggleDyslexicFont', setupAutogrow);
  }, []);

  const handleAutoSave = (ev) => {
    setShowSaveButton(!ev.detail.useAutoSave);
  };

  const toggleConfirmPopup = () => {
    setConfirmPopupIsOpen(!confirmPopupIsOpen);
  };

  const debouncedSave = useCallback(
    debounce(async (item) => {
      await save(item);
    }, getAutoSaveInterval()),
    [],
  );

  useEffect(() => {
    if (useAutoSave && updatedAt && !isUpdating) {
      setStatusSaving();
      debouncedSave({ title, description, type, quizQuestions, deletions, updatedAt });
    }
  }, [title, description, type, JSON.stringify(quizQuestions), deletions]);

  const onChangeTitle = useCallback((value) => {
    setTitle(value);
  }, []);

  const onChangeDescription = useCallback((value) => {
    setDescription(value);
  }, []);

  const toggleInfo = () => {
    setIsInfoShowing(!isInfoShowing);
  };

  const remove = () => {
    toggleConfirmPopup();
    document.dispatchEvent(new CustomEvent('deleteNode', { detail: { nodeId } }));
    panel.hide();
  };

  const addQuestion = () => {
    const newQuestions = [...quizQuestions];
    newQuestions.push({ body: '', order: quizQuestions.length });
    setQuizQuestions(newQuestions);
  };

  const save = async ({ title, description, type, quizQuestions, deletions, updatedAt }) => {
    const payload = {
      node_information: {
        name: title,
        type,
      },
      questions: quizQuestions,
      deletions: { questions: deletions },
      updated_at: updatedAt,
    };
    if (isArticle) {
      payload.content = description;
    } else {
      payload.body = description;
    }

    const nodeType = isArticle ? 'chunks' : 'behaviors';
    const response = await postData(`/api/v3/editor/${nodeType}/${nodeId}`, payload);
    if (response.success) {
      setStatusSaved();
      const body = response.body;
      setUpdatedAt(body.updated_at);
      if (type === 'written_quiz') {
        setQuizQuestions(body.data.QuizQuestions);
      }
      updateNode({ ...payload, uuid: nodeId });
      if (isArticle) {
        const node = graph.cy.$(`#${nodeId}`);
        const originalType = node.data('type');
        if (originalType !== 'chunk') {
          node.removeClass(originalType);
        }
        node.addClass(type);
        node.data({ type });
      }
    } else {
      setStatusSaveFailed(i18n.__('behavior_save_failed'));
      console.error(response);
      if (response.message === 'auth_failed') {
        flash(i18n.__('app_error_unauthorized'), { ttl: 5000 });
      } else if (response.message === 'edit_conflict') {
        flash(i18n.__('error_edit_conflict_behavior'), { ttl: 5000 });
      } else if (response.message === 'payload_too_large') {
        flash(i18n.__('error_payload_too_large'), { ttl: 20000 });
      } else {
        flash(i18n.__('error_behavior_save_failed'));
      }
    }
  };

  const selectType = () => {
    setShowTypeSelector(!showTypeSelector);
  };

  const copyNode = () => {
    const imageName = image ? image.split('/').reverse()[0] : '';
    document.dispatchEvent(
      new CustomEvent('copyToClipboard', {
        detail: { uuid: nodeId, name: title, imageName, type, copiedAt: Date.now() },
      }),
    );
  };

  if (!data) {
    return null;
  }

  const topSection = <NodeContentTopSection userToken={userToken} nodeType={type} panel={panel} />;

  const headerSection = (
    <HeaderSection>
      <HeaderText
        rows="1"
        aria-label={i18n.__('node_name_placeholder')}
        placeholder={i18n.__('node_name_placeholder')}
        value={title}
        onChange={(event) => {
          onChangeTitle(event.target.value);
        }}
        className="autogrow"
      />
    </HeaderSection>
  );

  const commonContent = (
    <ContentEditor
      type={type}
      panelType={panel.type}
      aria-label={i18n.__('placeholder_description')}
      placeholder={i18n.__('placeholder_description')}
      value={description}
      onChange={(value) => {
        !loading && onChangeDescription(value);
      }}
      integrations={integrations}
      fileService={fileService}
      canToggleToolbar={false}
      showToolbar={true}
    />
  );

  const contentSection = () => {
    switch (type) {
      case 'written_quiz':
        return (
          <WrittenQuizContentEditor
            questions={quizQuestions}
            setQuestions={setQuizQuestions}
            deletions={deletions}
            setDeletions={setDeletions}
            type={type}
            panelType={panel.type}
            fileService={fileService}
            loading={loading}
          >
            {commonContent}
          </WrittenQuizContentEditor>
        );
      default:
        return <ContentSection>{commonContent}</ContentSection>;
    }
  };

  const nodeFooterSection = () => {
    switch (type) {
      case 'written_quiz':
        return (
          <SecondaryButton
            onClick={addQuestion}
            text={i18n.__('activity_add_question')}
            className="add-question-button"
          />
        );
      default:
        return;
    }
  };

  const footerSection = (
    <FooterSection>
      {isInfoShowing && (
        <InfoPanel>
          <InfoText>{nodeDescription}</InfoText>
        </InfoPanel>
      )}
      <BottomPanel>
        <Wrapper>
          <DeleteIcon src={RemoveIcon} title={i18n.__('confirm_node_delete.title')} onClick={toggleConfirmPopup}>
            {i18n.__('app_delete')}
          </DeleteIcon>
          <CopyIcon src={CopyNodeIcon} title={i18n.__('app_copy_to_clipboard')} onClick={copyNode}>
            {i18n.__('app_copy')}
          </CopyIcon>
        </Wrapper>
        <InfoIcon
          src={isInfoShowing ? InfoIconActive : InfoIconInactive}
          onClick={toggleInfo}
          title={i18n.__('app_node_information')}
        />
        <Wrapper>
          {isArticle && (
            <TypeIcon
              src={getArticleTypeIcon(type)}
              title={i18n.__('select_symbol')}
              onClick={selectType}
              data-type={type}
              id="articleTypes"
            >
              {i18n.__('select_symbol')}
            </TypeIcon>
          )}
          {nodeFooterSection()}
          {showSaveButton && (
            <PrimaryButton
              onClick={() => save({ title, description, type, quizQuestions, deletions, updatedAt })}
              text={i18n.__('app_save')}
              className="save-button"
            />
          )}
        </Wrapper>
      </BottomPanel>
    </FooterSection>
  );

  const confirmPopup = (
    <Popup
      popupIsOpen={confirmPopupIsOpen}
      id="confirmPopup"
      heading={i18n.__('confirm_node_delete.title')}
      description={i18n.__('confirm_node_delete.body', { name: title })}
      buttonLabel={i18n.__('confirm_node_delete.action')}
      onClose={() => setConfirmPopupIsOpen(false)}
      onClick={remove}
    />
  );

  const menuOptions = ['chunk', 'video', 'link'].map((articleType) => ({
    name: i18n.__(`chunk_type_${articleType}`),
    src: getArticleTypeIcon(articleType),
    tag: articleType,
    onSelect: (selectedType) => {
      setType(selectedType);
      setShowTypeSelector(false);
      updateNode({ uuid: nodeId, name: title, type: articleType });
    },
  }));

  const menuPopup = isArticle && (
    <PopupMenu
      id="articleTypes"
      popupIsOpen={showTypeSelector}
      onClose={() => setShowTypeSelector(false)}
      options={menuOptions}
    />
  );

  return (
    !loading && (
      <PanelWrapper>
        {topSection}
        {headerSection}
        {contentSection()}
        {footerSection}
        {confirmPopup}
        {menuPopup}
      </PanelWrapper>
    )
  );
};

export default NodeContentEditor;

NodeContentEditor.propTypes = {
  nodeId: PropTypes.string.isRequired,
  editor: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  fileService: PropTypes.object.isRequired,
  flash: PropTypes.func.isRequired,
};
