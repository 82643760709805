import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  padding: 0;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme, secondary }) => (secondary ? theme.colors.transparent : theme.colors.buttonGreenFront)};
  border-radius: 18px;
  background-color: ${({ theme, secondary }) => (secondary ? theme.colors.buttonGreenFront : theme.colors.transparent)};
  color: ${({ theme, secondary, inNodePane }) =>
    secondary || inNodePane ? theme.colors.black : theme.colors.buttonGreenFront};
  padding: 9px 18px;
  height: 36px;
  min-width: 84px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  transition:
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  white-space: nowrap;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    transition:
      border-color 0.2s ease-in-out,
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    border-color: ${({ theme, secondary, inNodePane }) =>
      secondary && inNodePane ? theme.colors.standardComponentBlack : theme.colors.white};
    background-color: ${({ theme, secondary, inNodePane }) =>
      secondary || inNodePane ? theme.colors.buttonGreenFront : theme.colors.standardComponentBlack};
    color: ${({ theme, secondary, inNodePane }) =>
      secondary || inNodePane ? theme.colors.black : theme.colors.buttonGreenFront};
  }

  :disabled {
    opacity: 0.5;
  }
`;
