import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper, Button } from './styles';

const IconButton = ({ children, isExperimental = false, href, ...rest }) => {
  const [inNodePane, setInNodePane] = useState(false);

  useEffect(() => {
    setInNodePane(!!document.querySelector(href ? 'a' : 'button').closest('.node-pane'));
  }, []);

  const buttonRef = useRef(null);

  const mouseUp = (ev) => {
    ev.target.blur();
  };

  useEffect(() => {
    buttonRef.current.addEventListener('mouseup', mouseUp);
    return () => {
      if (buttonRef.current) {
        buttonRef.current.removeEventListener('mouseup', mouseUp);
      }
    };
  }, []);

  return (
    <ButtonWrapper>
      <Button
        ref={buttonRef}
        as={href ? 'a' : 'button'}
        href={href}
        tabIndex={0}
        isExperimental={isExperimental}
        inNodePane={inNodePane}
        {...rest}
      >
        <span>{children}</span>
      </Button>
    </ButtonWrapper>
  );
};

IconButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node.isRequired,
  href: PropTypes.string,
  backgroundColor: PropTypes.string,
  isExperimental: PropTypes.bool,
  rest: PropTypes.object,
};

export default IconButton;
