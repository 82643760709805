import styled, { css } from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const ViewSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 14px;
  padding: 0 20px;

  @media ${({ theme }) => theme.sizes.tablet} {
    padding: 0px 42px;
  }

  .ql-editor {
    p {
      margin: 0 0 15px;
    }
  }
`;

export const HeaderUserImage = styled.div`
  height: 30px;
  width: 30px;
  background-size: cover;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  background-position: 50%;
  border-radius: 50%;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const HeaderUserName = styled.div``;

export const HeaderRequestFeedback = styled.button`
  background-color: ${({ theme }) => theme.colors.transparent};
  outline: none;
  border: none;
  cursor: pointer;
  height: 22px;
  width: 22px;
  margin-left: 20px;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-left: 30px;
  }

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  ${({ isEditable }) =>
    !isEditable &&
    css`
      background-color: ${({ theme }) => theme.colors.black};
      background-position: 50%;
      background-size: 12px;
      background-repeat: no-repeat;
      border-radius: 50%;
    `}
`;

export const AutoSaveStatus = styled.div`
  color: ${({ theme }) => theme.colors.black_6};
  margin-right: 10px;
  height: 20px;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToolbarButton = styled.button`
  background-image: url(/assets/images/theme-2/icons/show-toolbar.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.transparent};
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  flex-shrink: 0;
  opacity: 0.7;
  transition: opacity 0.2s ease;

  &:hover:not(&:disabled),
  &:focus:not(&:disabled) {
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  &.show {
    background-image: url(/assets/images/theme-2/icons/hide-toolbar.svg);
  }

  &.attachment {
    background-image: url(/assets/images/theme-2/icons/text-editor/plus-_attach-file.svg);
  }

  &.video {
    background-image: url(/assets/images/theme-2/icons/text-editor/plus-_record-movie.svg);
  }

  &.audio {
    background-image: url(/assets/images/theme-2/icons/text-editor/plus-_reccord-sound.svg);
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const ButtonPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 25px;
  align-items: center;
  font-size: 14px;
`;
