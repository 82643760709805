import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper, Button } from './styles';

const PrimaryButton = ({ text, type = 'button', onClick, secondary = false, ...rest }) => {
  const [inNodePane, setInNodePane] = useState(false);
  const buttonRef = useRef();

  useEffect(() => {
    if (buttonRef?.current) {
      setInNodePane(!!buttonRef?.current.closest('.node-pane'));
    }
  }, [buttonRef]);

  const onClickHandler = (ev) => {
    ev.target.blur();
    onClick && onClick(ev);
  };

  return (
    <ButtonWrapper>
      <Button
        ref={buttonRef}
        type={type}
        onClick={onClickHandler}
        onKeyPress={onClickHandler}
        tabIndex={0}
        secondary={secondary}
        inNodePane={inNodePane}
        {...rest}
      >
        {text}
      </Button>
    </ButtonWrapper>
  );
};

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  rest: PropTypes.object,
};

export default PrimaryButton;
