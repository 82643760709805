import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../ContentEditor';
import HeaderToolbar from './HeaderToolbar';
import NodeActivityEditor from '../../NodeActivityEditor';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import { ViewSection, AutoSaveStatus } from '../styles';
import { Questions, Question, SubmitPanel } from '../../NodeContentPreview/styles';

const WrittenQuizContentViewer = ({
  type,
  report,
  isEditable,
  saveReport,
  data,
  fileService,
  autoSaveStatus,
  integrations,
  panel,
  isTeacher,
}) => {
  const questionToolbarToggles = data.QuizQuestions.reduce((acc, curr) => ((acc[curr.id] = false), acc), {});
  const [showToolbar, setShowToolbar] = useState(questionToolbarToggles);

  const onChangeReport = (value, id) => {
    const answers = { ...report.answers, [id]: { body: value, isDraft: true } };
    saveReport({ ...report, answers, editorId: id });
  };

  const revokeReport = () => {
    Object.values(report.answers).forEach((answer) => {
      answer.isDraft = true;
    });
    saveReport({ ...report, state: 'active', answers: report.answers });
  };

  const submitReport = () => {
    Object.values(report.answers).forEach((answer) => {
      answer.isDraft = false;
    });
    saveReport({ ...report, state: 'done', answers: report.answers });
  };

  const toggleToolbar = (id) => {
    setShowToolbar({ ...showToolbar, [id]: !showToolbar[id] });
  };

  const toggleComments = (answerId) => {
    document.dispatchEvent(
      new CustomEvent('toggleComments', { detail: { entityId: answerId, entityType: 'quiz_answer' } }),
    );
  };

  if (!report || !data) {
    return null;
  }

  if (!report.answers) {
    report.answers = {};

    if (!isTeacher) {
      report.QuizAnswers.forEach((answer) => {
        report.answers[`_${answer.question_id}`] = { body: answer.text, uuid: answer.uuid };
      });
    }
  }

  const getAnswer = (question) => {
    if (report.answers && report.answers[`_${question.id}`]) {
      return report.answers[`_${question.id}`];
    } else {
      return {};
    }
  };

  const getAnswerId = (question) => {
    const answer = getAnswer(question);
    return answer.uuid;
  };

  const getAnswerText = (question) => {
    const answer = getAnswer(question);
    return answer.body || '';
  };

  const getViewForQuestion = (question, index) => {
    const editorPanel = (
      <ContentEditor
        id={`_${question.id}`}
        type={type}
        panelType={panel.type}
        aria-label={i18n.__('placeholder_description')}
        placeholder={i18n.__('placeholder_description')}
        value={getAnswerText(question)}
        onChange={(value, id) => isEditable && onChangeReport(value, id)}
        integrations={integrations}
        fileService={fileService}
        disabled={!isEditable}
        showToolbar={showToolbar[question.id]}
        className="question"
      />
    );

    const toggleQuestionComments = () => toggleComments(getAnswerId(question));
    const setQuestionToolbar = () => toggleToolbar(question.id);

    const headerRightPanel = (
      <HeaderToolbar
        isEditable={isEditable}
        report={report}
        showToolbar={showToolbar[question.id]}
        setShowToolbar={setQuestionToolbar}
        toggleComments={toggleQuestionComments}
      />
    );

    const buttonPanel = <AutoSaveStatus>{autoSaveStatus[`_${question.id}`]}</AutoSaveStatus>;

    return (
      <NodeActivityEditor
        type={type}
        index={index}
        headerText={question.body}
        headerRightPanel={headerRightPanel}
        editorPanel={editorPanel}
        buttonPanel={buttonPanel}
        className="question"
      />
    );
  };

  const questions = data.QuizQuestions.map((question, index) => (
    <Question key={question.id}>{getViewForQuestion(question, index)}</Question>
  ));

  return (
    <>
      <ViewSection>
        <Questions>{questions}</Questions>
      </ViewSection>
      <SubmitPanel>
        {isEditable ? (
          <SecondaryButton text={i18n.__('app_handin')} onClick={submitReport} disabled={isTeacher} />
        ) : (
          <PrimaryButton text={i18n.__('app_edit')} onClick={revokeReport} />
        )}
      </SubmitPanel>
    </>
  );
};

export default WrittenQuizContentViewer;

WrittenQuizContentViewer.propTypes = {
  type: PropTypes.string.isRequired,
  report: PropTypes.object,
  isEditable: PropTypes.bool.isRequired,
  saveReport: PropTypes.func.isRequired,
  data: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  integrations: PropTypes.object.isRequired,
  autoSaveStatus: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
};
