import styled, { css } from 'styled-components';
import TextField from '../../components/TextField';

export const PageWrapper = styled.div`
  max-width: 840px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterPanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.sizes.tablet} {
    margin-bottom: 66px;
  }
`;

export const SessionFilters = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.robotoLight};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.headerGrey};
  width: 100%;
  flex-flow: wrap;
`;

export const SessionSearch = styled.div`
  display: flex;
`;

export const SessionPanelWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 22px;
  margin-left: 0;
  border-radius: 58px;
  height: 117px;
  border: 2px solid ${({ theme }) => theme.colors.sessionBorder};
  padding: 20px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.2s border-color ease-in-out;

  &:hover,
  &:focus {
    transition: 0.2s border-color ease-in-out;
    border-color: ${({ theme }) => theme.colors.sessionBorderHover};
  }

  @media ${({ theme }) => theme.sizes.tablet} {
    padding-right: 35px;
  }

  @media ${({ theme }) => theme.sizes.desktop} {
    margin-left: -22px;
  }
`;

export const SettingsHeader = styled.label``;

export const NewSessionBadge = styled.div`
  position: absolute;
  padding: 3px 7px;
  font-size: 10px;
  border: 2px solid ${({ theme }) => theme.colors.sessionBorder};
  border-top: none;
  top: 0;
  left: 50%;
`;

export const SessionImagePanel = styled.div``;
export const SessionInfoPanel = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 75px);

  @media ${({ theme }) => theme.sizes.tablet} {
    width: calc(100% - 160px);
  }
`;
export const SessionTextPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 75px);

  @media ${({ theme }) => theme.sizes.tablet} {
    width: calc(100% - 160px);
  }
`;
export const SessionButtonPanel = styled.div`
  display: flex;
  align-items: center;

  a {
    span {
      display: none;
    }

    @media ${({ theme }) => theme.sizes.tablet} {
      span {
        display: inline-block;
      }
    }
  }

  div:first-of-type {
    margin-right: 10px;

    a {
      border-color: ${({ theme }) => theme.colors.buttonGreenFront};
    }

    a span {
      font-weight: bold;
    }
  }
`;
export const SubjectWrapper = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.headerGrey};
  white-space: nowrap;
`;
export const StudentCount = styled.div`
  display: flex;
`;
export const TeacherName = styled.div`
  display: flex;
`;

export const Separator = styled.div`
  color: ${({ theme }) => theme.colors.buttonGreenFront};
  margin: 0 10px;
  font-size: 20px;
  line-height: 12px;
  display: none;
  @media ${({ theme }) => theme.sizes.tablet} {
    display: flex;
  }
`;
export const SubjectTags = styled.div`
  display: none;
  @media ${({ theme }) => theme.sizes.tablet} {
    display: flex;
  }
`;

export const SessionTitle = styled.div`
  font-size: 19px;
  margin: 3px 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
export const SessionSubtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.headerGrey};
  white-space: nowrap;
  width: ${({ isTeacher }) => (isTeacher ? '170px' : '135px')};
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media ${({ theme }) => theme.sizes.tablet} {
    width: 100%;
  }
`;
export const ProgressText = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.progressBarGreen};
`;

export const ProgressBarWrapper = styled.div`
  display: none;
  @media ${({ theme }) => theme.sizes.tablet} {
    display: flex;
    margin-left: 10px;
  }
`;
export const PlayButtonWrapper = styled.div`
  margin-left: 15px;
  width: 34px;
  height: 34px;
  border: 2px solid ${({ theme }) => theme.colors.transparent};
  border-radius: 50%;
  transition: border 0.2s ease-in-out;
  &:hover,
  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${({ theme }) => theme.colors.white};
  }

  ${({ icon }) =>
    icon &&
    css`
      background: url(${icon}) center center no-repeat;
    `}

  @media ${({ theme }) => theme.sizes.tablet} {
    width: 28px;
    height: 28px;
  }
`;

export const Button = styled.a``;

export const Wrapper = styled.div`
  display: flex;
`;
export const ButtonWrapper = styled(Wrapper)`
  button {
    margin-left: 20px;
  }
`;
export const EmptyListPanelWrapper = styled(Wrapper)`
  flex-direction: column;
`;
export const ColumnWrapper = styled(Wrapper)`
  flex-direction: column;
  white-space: nowrap;
`;
export const DateWrapper = styled(Wrapper)`
  margin-left: 24px;
`;
export const ContentPanel = styled.div`
  height: 490px;

  label > div {
    background-position: center left;
  }
`;
export const Content = styled.div`
  white-space: normal;
  margin-bottom: 20px;
`;
export const BottomPanel = styled.div`
  display: flex;
`;
export const LeftPanel = styled.div`
  width: 393px;
`;
export const SessionNameWrapper = styled.div`
  display: flex;

  div:first-child {
    width: 90%;
  }
`;
export const SessionName = styled(TextField)`
  > input {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white_7};
  }
`;
export const OrganizationInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 30px;
`;
export const AdvancedSettings = styled.div`
  margin-top: ${({ show }) => (show ? '5px' : '0px')};
`;
export const RightPanel = styled.div`
  margin-left: 30px;

  > div:last-of-type {
    margin-top: 0;
  }
`;
export const InviteHeader = styled.div`
  font-weight: bold;
`;
export const InvitedLabel = styled.label`
  font-size: 14px;
`;
export const SelectionListWrapper = styled.div`
  display: flex;
  margin-bottom: -20px;

  > div:first-of-type {
    border: 2px solid ${({ theme }) => theme.colors.white_5};
    border-radius: 5px;
    width: 417px;
    height: ${({ showInvitationEmail }) => (showInvitationEmail ? '150px' : '228px')};
    overflow-y: auto;
    margin-top: 0;
    flex-direction: row;
  }

  button {
    margin-left: 15px;
  }
`;
export const GroupInvitiation = styled.div`
  margin-bottom: 20px;
`;
export const ParticipantInvitiation = styled.div``;
export const SearchLabel = styled.label`
  font-size: 14px;
`;
export const GroupList = styled.div`
  display: flex;
  align-items: center;

  > div:first-of-type {
    margin-bottom: 0;
  }
`;
export const GroupListLabel = styled.label`
  font-size: 14px;
  margin: 20px 0 5px;
`;
export const GroupAddButton = styled.div`
  margin-left: 15px;
`;
export const ButtonPanel = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: ${({ isNew }) => (isNew ? 'space-between' : 'flex-end')};
  align-items: center;

  .start-later {
    margin: 0 10px;
  }
`;

export const SettingsToggle = styled.label`
  color: ${({ theme }) => theme.colors.white};
  font-weight: normal;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.transparent};
  transition: border-color 0.2s ease;

  &:after {
    content: ${({ show }) =>
      show ? 'url(/assets/images/icons/arrow-up.svg)' : 'url(/assets/images/icons/arrow-down.svg)'};
    margin-left: 10px;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.white};
    transition: border-color 0.2s ease;
  }
`;
export const SettingsPanel = styled.div`
  flex-direction: column;
  margin: 10px 0;
`;
export const SessionSetting = styled.div`
  margin: 10px 0;

  label > div {
    background-position: 0 5px;
  }
`;
export const RadioButtonWrapper = styled.div`
  margin-left: 24px;
  margin-top: 5px;

  label {
    color: ${({ isEnabled, theme }) => (isEnabled ? theme.colors.white : theme.colors.white_4)};
    cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'default')};
    pointer-events: ${({ isEnabled }) => (isEnabled ? 'all' : 'none')};

    & > div {
      opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};
    }
  }
`;

export const DateDivider = styled.span`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.white_4 : theme.colors.white)};
  margin: 4px 10px 0;
`;

export const EmailInvitation = styled.div`
  input {
    width: 417px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.buttonGreenFront};
    }
  }

  label {
    font-size: 14px;
  }
`;
