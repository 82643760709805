import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NodeActivityEditor from '../../NodeActivityEditor';
import CommentsIcon from '../../../../public/assets/images/theme-2/icons/asterisk-white.svg';
import SneakPeakIcon from '../../../../public/assets/images/theme-2/icons/toolbar/visible-black.svg';
import { ViewSection, HeaderUserImage, HeaderUserName, HeaderRequestFeedback, SneakPeak } from '../styles';
import { EditorHeaderRowLeft, Editor, EditorHeaderRowRight } from '../../NodeActivityEditor/styles';

const TaskContentTeacherPanel = ({ type, student, report }) => {
  const { name: studentName, image: studentImage } = student;
  const [hasPeaked, setHasPeaked] = useState(false);

  if (!report) {
    return null;
  }

  const { uuid: entityId, state: reportState, type: entityType, body: reportBody, draft: reportDraft } = report;
  const workNotStarted = reportState === 'blank' || !reportBody;
  const workInProgress = reportState === 'active' && reportDraft;

  const toggleReportComments = () => {
    document.dispatchEvent(new CustomEvent('toggleComments', { detail: { entityId, entityType } }));
  };

  const sneakPeak = () => {
    setHasPeaked(true);
  };

  let editorContent = reportBody;
  if (workNotStarted) {
    editorContent = i18n.__('work_not_started');
  } else if (workInProgress && !hasPeaked) {
    editorContent = i18n.__('work_in_progress');
  }

  const headerPanel = (
    <>
      <EditorHeaderRowLeft>
        <HeaderUserImage src={studentImage} />
        <HeaderUserName>{studentName}</HeaderUserName>
      </EditorHeaderRowLeft>
      <EditorHeaderRowRight>
        <HeaderRequestFeedback onClick={toggleReportComments} src={CommentsIcon} />
      </EditorHeaderRowRight>
    </>
  );

  const editorPanel = (
    <Editor
      showStatusText={workNotStarted || (workInProgress && !hasPeaked)}
      dangerouslySetInnerHTML={{ __html: editorContent }}
    />
  );

  const sneakPeakPanel =
    workInProgress && !hasPeaked ? (
      <SneakPeak onClick={sneakPeak} src={SneakPeakIcon}>
        {i18n.__('sneak_peak')}
      </SneakPeak>
    ) : null;

  return (
    <ViewSection>
      <NodeActivityEditor
        type={type}
        headerPanel={headerPanel}
        editorPanel={editorPanel}
        sneakPeakPanel={sneakPeakPanel}
      />
    </ViewSection>
  );
};

export default TaskContentTeacherPanel;

TaskContentTeacherPanel.propTypes = {
  type: PropTypes.string.isRequired,
  student: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
};
