import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTransition } from '@react-spring/web';
import useLocalStorage from '../../hooks/useLocalStorage';
import useKeyPress from '../../hooks/useKeyPress';
import { checkInTeams } from '../../utils/teams';
import {
  ComponentWrapper,
  PurpleGuideWrapper,
  PurpleGuideTab,
  TabText,
  PurpleGuideContent,
  GuideClose,
  Header,
  SubHeader,
  Collection,
  CollectionItem,
  Footer,
  Mask,
} from './styles';

const PurpleGuide = ({
  id,
  userId,
  texts,
  defaultOpen = true,
  saveInLocalStorage = true,
  forceOpen = false,
  TabComponent = PurpleGuideTab,
  WrapperComponent = PurpleGuideWrapper,
  openClassName = 'guide-open',
  context = {},
  content,
  onClose,
  hasTrialPanel,
}) => {
  const purpleGuideTexts = i18n.locales[i18n.getLocale()]['purpleGuideTexts'] || {};
  const currentGuideTexts = { ...purpleGuideTexts[id], ...texts };
  const {
    tabheader = i18n.__('guide_tabheader'),
    header = '',
    subheader = i18n.__('guide_subheader'),
    footer = i18n.__('guide_footer'),
    items = [],
  } = currentGuideTexts;
  const purpleGuideKey = `purpleGuide_${id}_${userId}`;
  const inTeams = checkInTeams();
  const disabledByUser = document.querySelector('body').getAttribute('data-user-disable-purple-guide') === 'true';

  const [collapsed, setCollapsed] =
    !disabledByUser && !inTeams && saveInLocalStorage && !forceOpen
      ? useLocalStorage(purpleGuideKey, !defaultOpen)
      : useState(inTeams || disabledByUser ? !forceOpen : !forceOpen || !defaultOpen);

  const showMask = !collapsed && ['trial-open', 'news-open'].includes(openClassName);
  const body = document.querySelector('body');
  const hasScrollbar = window.innerWidth > document.documentElement.clientWidth || body.classList.contains('horizon');
  const shouldHideGuide = window.innerWidth < 768; // Define small devices

  const setGlobalStateClass = (collapsed) => {
    if (!collapsed) {
      document.querySelector('body').classList.add(openClassName);
    } else {
      document.querySelector('body').classList.remove(openClassName);
    }
  };

  const onClick = () => {
    setCollapsed(!collapsed);
    onClose && onClose();
  };

  const collectionItems = Object.values(items)
    .filter((item) => {
      if (typeof item === 'string') {
        return true;
      } else {
        return !!context[Object.keys(item)[0]];
      }
    })
    .map((item, index) => (
      <CollectionItem
        key={index}
        dangerouslySetInnerHTML={{
          __html: typeof item === 'string' ? item : Object.values(item)[0],
        }}
      />
    ));

  const contentPanelTransitions = useTransition(!collapsed, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' },
  });

  const tabTransitions = useTransition(collapsed, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const maskTransitions = useTransition(showMask, {
    onStart: () => {
      if (!collapsed && showMask && hasScrollbar) {
        body.classList.add('scrollbar');
      }
    },
    from: { opacity: 0 },
    enter: { opacity: 0.7 },
    leave: { opacity: 0 },
    onRest: () => {
      if (collapsed) {
        body.classList.remove('scrollbar');
      }
    },
  });

  const checkScrollPosition = () => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    element.classList.remove('top');
    element.classList.remove('bottom');

    if (element.scrollTop === 0) {
      element.classList.add('top');
    }

    if (Math.round(element.scrollHeight - element.scrollTop) === element.clientHeight) {
      element.classList.add('bottom');
    }
  };

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      checkScrollPosition();
      element.addEventListener('scroll', checkScrollPosition);
      return () => {
        element.removeEventListener('scroll', checkScrollPosition);
      };
    }
  });

  useEffect(() => {
    if (content && forceOpen) {
      setCollapsed(false);
    }
  }, [forceOpen]);

  useKeyPress(
    'Escape',
    () => {
      setCollapsed(true);
      onClose && onClose();
    },
    !collapsed,
  );

  setGlobalStateClass(collapsed);

  const purpleGuide = shouldHideGuide ? null : (
    <ComponentWrapper className="purple-guide">
      {tabTransitions(
        (style, show) =>
          show && (
            <TabComponent title={tabheader} style={style} onClick={onClick} onKeyPress={onClick} tabIndex={0}>
              <TabText>{tabheader}</TabText>
            </TabComponent>
          ),
      )}
      {contentPanelTransitions(
        (style, show) =>
          show && (
            <WrapperComponent id={id} style={style} $hasTrialPanel={hasTrialPanel}>
              <GuideClose
                role="button"
                title={i18n.__('app_close')}
                aria-label={i18n.__('app_close')}
                onClick={onClick}
                onKeyPress={onClick}
                tabIndex={0}
              />

              <PurpleGuideContent>
                {header && <Header>{header}</Header>}
                {subheader && <SubHeader>{subheader}</SubHeader>}
                <Collection>
                  {content || (
                    <>
                      {collectionItems}
                      <Footer dangerouslySetInnerHTML={{ __html: footer }} />
                    </>
                  )}
                </Collection>
              </PurpleGuideContent>
            </WrapperComponent>
          ),
      )}
      {maskTransitions((style, show) => show && <Mask style={style} />)}
    </ComponentWrapper>
  );

  return ReactDOM.createPortal(purpleGuide, document.querySelector('[role="main"]') || document.querySelector('body'));
};

PurpleGuide.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  trialDays: PropTypes.number,
  defaultOpen: PropTypes.bool,
  saveInLocalStorage: PropTypes.bool,
  texts: PropTypes.shape({
    header: PropTypes.string,
    subHeader: PropTypes.string,
    footer: PropTypes.string,
    guideTab: PropTypes.string,
    items: PropTypes.array,
  }),
  context: PropTypes.shape(),
  content: PropTypes.object,
  onClose: PropTypes.func,
  hasTrialPanel: PropTypes.bool,
};

export default PurpleGuide;
