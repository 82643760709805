import 'parsleyjs';
import Remote from './remote';
import Modal from './modals2';
import flash from './flash';
import Utils from './utils';
import { DropDownRenderer, ExpiredAccountPopupRenderer, LinkEmailPopupRenderer } from '../shared/reactLoader';
import MS from './integrations/microsoft';
import { isInTeamsChannel } from '../../../../src/utils/teams';

const emailRegex =
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default class Authenticate {
  get eventSource() {
    if (!this._eventsource) {
      this._eventsource = $({});
    }
    return this._eventsource;
  }

  constructor(options = {}) {
    this.options = options;
    this.remote = new Remote('');
  }

  static async signInMSCallback(hash) {
    const params = Utils.hashParams(hash);
    const { id_token: token } = params;
    const tenantId = 'common';
    const options = {};
    return this.teamsLoginWithToken(token, tenantId, false, options);
  }

  static async signInMS(options = {}) {
    let loginRequest = new this();
    loginRequest.signInMS(options);
  }

  async signInMS(options = {}) {
    const msSignin = new MS({
      azureClientId: options.azureClientId,
      appUrl: options.appUrl,
    });

    const accessScopes = ['openid', 'offline_access', 'user.read', 'profile'];

    const control = options.loginHint ? `login_hint=${options.loginHint}` : 'prompt=select_account';

    const { token, tenantId, user } = await msSignin.adalLogin({
      extraQueryParameter: `scope=${encodeURIComponent(accessScopes.join(' '))}&${control}`,
      ...options,
    });

    return this.teamsLoginWithToken(token, tenantId, false, options);
  }

  static async signInWithTeams(options = {}) {
    const msSignin = new MS({
      azureClientId: options.azureClientId,
      appUrl: options.appUrl,
    });

    if (!options.skipSilentLogin) {
      const { token, tenantId } = await msSignin.teamsSilentLogin();

      if (token) {
        return this.teamsLoginWithToken(token, tenantId, msSignin, options);
      }
    }

    this.login({
      onAuthenticated: false,
      signInWithTeams: msSignin,
      code: options.code,
    });
  }

  static login(options = {}) {
    let loginRequest = new this();
    return loginRequest.login({
      onAuthenticated: false,
      ...options,
    });
  }

  static teamsLoginWithToken(token, tenantId, msSignin, options) {
    let loginRequest = new this();
    loginRequest.teamsLoginWithToken(token, tenantId, msSignin, options);
  }

  teamsLoginWithToken(token, tenantId, msSignin = undefined, options = {}) {
    const loginObject = this;
    let promise = Promise.resolve(false);
    if (msSignin) {
      promise = msSignin.getContext();
    }
    promise.then((context) => {
      let subEntityId = '';
      if (options.code) {
        subEntityId = `code/${options.code}`;
      } else if (context) {
        subEntityId = context.subEntityId;
      }

      this.remote
        .post('integrations/microsoft/login', {
          token,
          tenantId,
          subEntityId,
        })
        .then((result = {}) => {
          if (result.pendingToken) {
            window.pending_token = result.pendingToken;
          }
          let path = '/me';
          if (result.location && result.location.startsWith('/confirm_signup')) {
            path = result.location;
          } else if (options.code) {
            path = `code/${options.code}`;
          } else if (result.location) {
            path = result.location;
          }
          window.location.assign(path);
        })
        .catch((error) => {
          if (parseInt(error.code) === 402) {
            return loginObject.expiredAccountPopup();
          }

          flash(i18n.__('error_sso_login', { ttl: 3000 }));
          if (msSignin) {
            this.login({
              onAuthenticated: false,
              signInWithTeams: msSignin,
            });
          }
        });
    });
  }

  login(options = { onAuthenticated: false }) {
    const loginObject = this;
    this.pendingToken = window.pending_token ? window.pending_token : null;
    if (this.pendingToken && this.pendingToken.preferred_locale) {
      i18n.setLocale(this.pendingToken.preferred_locale);
    }

    const { email, code } = options;
    const modal = new Modal(
      Handlebars.templates['login_modal']({
        email,
        code,
        signInWithTeams: !!options.signInWithTeams,
        signInWithMicrosoft: true,
        signInWithFeide: true,
      }),
      {
        class: 'lp--authenticationModal',
        disableEscape: !options.onAuthenticated,
      },
    );

    modal.eventSource.one('willHide.panel', () => {
      window.location.hash = '';
    });

    const promise = new Promise((resolveRequest, rejectRequest) => {
      if (options.signInWithTeams) {
        modal.content.find('.lp--js-teams-signin').on('click.authenticate', (ev) => {
          ev.preventDefault();
          options.signInWithTeams.teamsLogin().then(async ({ token, tenantId }) => {
            this.teamsLoginWithToken(token, tenantId, options.signInWithTeams, options);
          });
        });
      }

      modal.content.find('.lp--js-microsoft-signin').on('click.authenticate', (ev) => {
        ev.preventDefault();
        const appUrl = window.location.origin;
        const options = {
          azureClientId: document.body.getAttribute('data-azure-client-id'),
          appUrl,
          popUp: false,
          redirectUri: `${appUrl}/`,
        };
        this.signInMS(options);
      });

      modal.content.find('.lp--js-feide-signin').on('click.authenticate', (ev) => {
        ev.preventDefault();
        window.location = '/oidc/feide';
      });

      modal.content.find('.lp--js-skolon-signin').on('click.authenticate', (ev) => {
        ev.preventDefault();
        window.location = '/oauth2/skolon';
      });

      modal.content.find('.new-in-loops__button').on(Utils.interactionEvent, (ev) => {
        location.href = i18n.__('login_modal_new_in_loops_read_more_link');
      });

      modal.content.find('.lp--js-form').on('submit.authenticate', (ev) => {
        ev.preventDefault();
        let form = modal.content.find('.lp--js-form');
        let form_data = form.serialize() + '&code_prefilled=' + !!form.find('[name=code]').val();

        $.post(form.attr('action'), form_data, (data, textStatus, jqXHR) => {
          if (jqXHR.status == 200) {
            if (data.redirect) {
              window.location.href = data.redirect;
              rejectRequest('redirected');
              return;
            }
            $('body').data('userUuid', data.uuid);
            modal.hide();
            data.email = form.find('input[name=username]').val();
            resolveRequest(data);
          }
        }).fail(function (err) {
          if (err.status === 402) {
            return loginObject.expiredAccountPopup();
          } else if (err.responseJSON && err.responseJSON.error && err.responseJSON.message) {
            flash(err.responseJSON.message, { ttl: 3000 });
          } else {
            flash(i18n.__('login_modal_mismatch'), { ttl: 3000 });
          }
        });
      });
    });

    if (options.onAuthenticated) {
      return promise;
    } else {
      promise.then((data) => {
        if (this.pendingToken && data.ignore_token != true) {
          window.location.href = `/invite/token/${this.pendingToken.uuid}`;
        } else {
          window.location.href = '/me';
        }
      });
    }
    // This is only temporary - we'll want to display any errors, and then redirect!

    modal.content.find('.lp--js-ssoLogin').on(Utils.interactionEvent, (e) => {
      e.preventDefault();
      Authenticate.ssoLogin({ email: this.getEmailFromForm(modal), code });
    });

    modal.content.find('.lp--js-resetPwd').on(Utils.interactionEvent, (e) => {
      e.preventDefault();
      Authenticate.resetPassword({
        email: this.getEmailFromForm(modal),
        code,
      });
    });

    modal.content.find('.lp--js-signupLink').on(Utils.interactionEvent, (e) => {
      e.preventDefault();
      Authenticate.request_signup({
        email: this.getEmailFromForm(modal),
        code,
      });
    });

    this.togglePasswordVisibility(modal);

    modal.show();
  }

  static ssoLogin(options = {}) {
    let login = new this(options);
    return login.performSSO();
  }

  performSSO() {
    let that = this;

    this.pendingToken = window.pending_token ? window.pending_token : null;
    const message = this.pendingToken ? this.pendingToken : {};
    const { email, code } = this.options;
    const modal = new Modal(
      Handlebars.templates['login_sso_modal']({
        ...message,
        email,
        code,
      }),
      { class: 'lp--authenticationModal', disableEscape: true },
    );

    modal.content.find('.lp--js-form').on('submit.authenticate', (ev) => {
      ev.preventDefault();
      let form = modal.content.find('.lp--js-form');
      let form_data = form.serialize() + '&code_prefilled=' + !!form.find('[name=code]').val();
      $.post(form.attr('action'), form_data, (data, textStatus, jqXHR) => {
        // FIXME Temporary disable of sso
        if (jqXHR.status == 200) {
          if (data.redirect) {
            window.location.href = data.redirect;
            return;
          } else {
            throw new Error('Redirect expected');
          }
        }
      }).fail(function (err) {
        console.error(err);
        // FIXME Temporary disable of sso
        if (err.responseJSON && err.responseJSON.error && err.responseJSON.message) {
          flash(err.responseJSON.message);
        } else {
          flash(i18n.__('app_error_alert'));
        }
      });
    });

    modal.content.find('.lp--js-pwLogin').on(Utils.interactionEvent, (e) => {
      e.preventDefault();
      Authenticate.login({ email: this.getEmailFromForm(modal), code });
    });
    that.modal = modal;
    modal.show();
  }

  static resetPassword(options = {}) {
    let reset = new this(options);
    return reset.resetPassword();
  }

  resetPassword() {
    let that = this;
    const { email, code } = this.options;
    const modal = new Modal(Handlebars.templates['reset_password']({ email }), {
      class: 'lp--authenticationModal',
      disableEscape: true,
    });

    modal.eventSource.on('added.panel', () => {
      let panelWidth = modal.content.find('.lp--authenticateModalBody').outerWidth();

      modal.content.find('.lp--js-form').on('submit.reset', function (e) {
        e.preventDefault();
        const email = $(this).find('.lp--js-emailInput').val();
        if (!email || !emailRegex.test(email)) {
          flash(i18n.__('error_password_email_invalid'), { ttl: 3000 });
          return;
        }
        that.remote
          .post('reset_password', { email })
          .then((res) => {
            that.modal.content
              .find('.lp--authenticateModalBodyWrapper')
              .css({ transform: `translateX(-${panelWidth}px)` });

            that.modal.content
              .find('.reset-password__resend_email')
              .on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (ev) => {
                ev.preventDefault();
                that.remote
                  .post('reset_password', { email })
                  .then(() => {
                    flash(i18n.__('reset_password_resend_success'), {
                      ttl: 3000,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                    flash(i18n.__('error_password_reset_failed'), {
                      ttl: 3000,
                    });
                  });
              });
          })
          .catch((err) => {
            flash(i18n.__('error_password_reset_failed'), { ttl: 3000 });
          });
      });
    });

    modal.content.find('.lp--js-pwLogin').on(Utils.interactionEvent, (e) => {
      e.preventDefault();
      Authenticate.login({ email: this.getEmailFromForm(modal), code });
    });

    that.modal = modal;
    modal.show();
  }

  getEmailFromForm(modal) {
    return modal.content.find('input[type="email"]').val();
  }

  togglePasswordVisibility(modal) {
    modal.content.find('.show-password-toggle').on(Utils.interactionEvent, (e) => {
      $(e.target).toggleClass('show');
      const isVisible = $(e.target).hasClass('show');
      const type = isVisible ? 'text' : 'password';
      $('input[name=password]').get(0).type = type;
      $('.show-password-toggle').attr('title', i18n.__(isVisible ? 'hide_password' : 'show_password'));
    });
  }

  static resetPasswordConfirm(options = {}) {
    let reset = new this(options);
    return reset.resetPasswordConfirm();
  }

  resetPasswordConfirm(options) {
    let that = this;
    let modal = new Modal(
      Handlebars.templates['reset_password_confirm']({
        token: this.options.token,
      }),
      { class: 'lp--authenticationModal', disableEscape: true },
    );

    modal.eventSource.on('added.panel', () => {
      that.form = modal.content.find('.lp--js-form').parsley();
      let panelWidth = modal.content.find('.lp--authenticateModalBody').outerWidth();

      modal.content.find('.lp--js-form').on('submit.reset', function (e) {
        e.preventDefault();
        that.remote
          .post(`reset_password/${that.options.token}`, {
            password: $(this).find('.lp--js-passwordInput').val(),
          })
          .then((res) => {
            that.modal.content
              .find('.lp--authenticateModalBodyWrapper')
              .css({ transform: `translateX(-${panelWidth}px)` });
          })
          .catch((err) => {
            if (err.message) {
              flash(err.message, { ttl: 3000 });
            } else {
              flash(i18n.__('error_password_reset_failed'), { ttl: 3000 });
            }
          });
      });
    });

    modal.content.find('.lp--js-pwLogin').on(Utils.interactionEvent, function (e) {
      e.preventDefault();
      Authenticate.login();
    });

    this.togglePasswordVisibility(modal);

    that.modal = modal;

    that.remote
      .get(`reset_password/${that.options.token}`)
      .then((res) => {
        modal.show();
      })
      .catch((err) => {
        if (err.message) {
          flash(err.message, { ttl: 3000 });
        } else {
          flash(i18n.__('error_password_reset_failed'), { ttl: 3000 });
        }
      });
  }

  static joinByCode(options = {}) {
    let join = new this(options);
    return join.joinByCode();
  }

  async joinByCode(options) {
    let that = this;
    let user_data = Utils.getCurrentUserData();
    let orgType = window.code_token.organisationType;
    let user_missing = user_data.uuid ? false : true;
    let userClass = user_missing ? 'no-user' : 'user';

    const inTeamsChannel = await isInTeamsChannel();
    const disableEscape = user_missing || inTeamsChannel;
    const disableClose = inTeamsChannel;
    const hideBackground = inTeamsChannel;

    if (user_missing && !!inTeamsChannel) {
      return Authenticate.signInWithTeams({
        azureClientId: document.body.getAttribute('data-azure-client-id'),
        appUrl: window.location.origin,
        skipSilentLogin: false,
        code: this.options.code || '',
      });
    }

    let modal = new Modal(
      Handlebars.templates['join_code']({
        user_missing,
        code: this.options.code || '',
        codeToken: window.code_token,
        orgType,
        userClass,
      }),
      {
        class: 'lp--authenticationModal',
        disableEscape,
        disableClose,
        hideBackground,
      },
    );

    modal.eventSource.on('added.panel', () => {
      modal.content.find('.lp--js-form').on('submit.join', function (e) {
        e.preventDefault();
        modal.content.find('.lp--js-form input[type=submit]').attr('disabled', true);

        const code = $(this).find('#signupcode').val();
        const code_prefilled = code === that.options.code;

        let email = user_data.email;
        if (!user_data.uuid || !email) {
          email = $(this).find('#email').val();
          if (!email || !emailRegex.test(email)) {
            modal.content.find('.lp--js-form input[type=submit]').attr('disabled', false);
            flash(i18n.__('join_modal_invalid_email'));
            return;
          }
        }
        if (!(code && email) && !user_data.uuid) {
          modal.content.find('.lp--js-form input[type=submit]').attr('disabled', false);
          flash(i18n.__('join_modal_invalid_input'));
        } else {
          e.preventDefault();
          that.remote
            .post('session/code', {
              password: code,
              code_prefilled,
              email,
              preferred_locale: i18n.getLocale(),
              inTeamsChannel,
            })
            .then((res) => {
              if (!res.error) {
                if (res.redirect) {
                  window.location.href = res.redirect;
                } else {
                  window.location.href = `/#signup_code/${email}/${res.uuid}`;
                }
              } else {
                flash(res.error, { ttl: 3000 });
              }
            })
            .catch((err) => {
              modal.content.find('.lp--js-form input[type=submit]').attr('disabled', false);
              if (err.message) {
                flash(err.message);
              } else {
                flash(i18n.__('join_modal_code_error'));
              }
            });
        }
      });
    });

    modal.content.find('.login-button').on(Utils.interactionEvent, function (e) {
      e.preventDefault();
      const email = modal.content.find('#email').val();
      const code = modal.content.find('#signupcode').val();
      const code_prefilled = code === that.options.code;
      Authenticate.login({ email, code, code_prefilled });
    });

    modal.content.find('.lp--modalClose').on(Utils.interactionEvent, () => {
      history.pushState(null, null, ' ');
    });

    that.modal = modal;

    modal.show();
  }

  static request_signup(options = {}) {
    let signupRequest = new this(options);
    return signupRequest.request_signup();
  }

  request_signup() {
    const { email, code } = this.options;
    const modal = new Modal(
      Handlebars.templates['request_signup_modal']({
        email,
      }),
      { class: 'lp--authenticationModal' },
    );
    this.modal = modal;

    modal.eventSource.one('willHide.panel', () => {
      window.location.hash = '';
    });

    modal.content.find('.lp--js-requestSignup').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (ev) => {
      ev.preventDefault();
      let userData = modal.content.find('.lp--js-form').serializeObject();
      userData = $.extend(true, userData, { isNewOrganization: true });
      this.remote
        .post('request_signup', userData)
        .then(() => {
          window.location.href = `/#signup_code/${userData.username}`;
        })
        .catch((err) => {
          console.error(err);
          if (err.error === 'sso_account') {
            flash(i18n.__(err.message), { ttl: 3000 });
          } else if (err.error === 'logged_in') {
            flash(i18n.__(err.message), { ttl: 3000 });
          } else if (err.code === 205) {
            flash(err.message, { ttl: 3000 });
          } else {
            flash(i18n.__('app_error_alert'), { ttl: 3000 });
          }
        });
    });

    modal.content.find('footer a').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (ev) => {
      ev.preventDefault();
      Authenticate.login({ email: this.getEmailFromForm(modal), code });
    });

    modal.show();
  }

  static signup_code(email, uuid, options = {}) {
    let signupCode = new this(options);
    return signupCode.signup_code(email, uuid);
  }

  signup_code(email, uuid) {
    let modal = new Modal(Handlebars.templates['signup_code']({ email }), {
      class: 'lp--authenticationModal',
    });
    this.modal = modal;

    modal.content.find('.lp--js-resendMail').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (ev) => {
      ev.preventDefault();
      this.remote
        .post('request_signup', { username: email })
        .then(() => {
          flash(i18n.__('signup_modal_enter_code_resend_success'), {
            ttl: 3000,
            type: 'status',
          });
        })
        .catch((err) => {
          console.error(err);
          flash(i18n.__('app_error_alert'), { ttl: 3000 });
        });
    });

    modal.eventSource.on('willHide.panel', function () {
      window.location.href = '#';
    });

    modal.show();
  }

  static signup(options = {}) {
    let signupRequest = new this(options);
    return signupRequest.signup(options.pendingToken);
  }

  signupCreateOrganisation() {
    let modal = new Modal(Handlebars.templates['signup_modal_organisation']({}), {
      class: 'lp--authenticationModal',
      disableEscape: true,
    });

    modal.eventSource.on('added.panel', () => {
      this.signupBindOrganisationForm(modal);

      this.panelWidth = modal.content.find('.lp--authenticateModalBody').outerWidth();

      this.form = modal.content.find('.lp--js-form').parsley();

      modal.content.find('.lp--js-nextSignupStep').on(Utils.interactionEvent, (ev) => {
        ev.preventDefault();

        const formData = modal.content.find('.lp--js-form').serializeObject();
        this.form
          .whenValidate()
          .done(() => {
            this.userData = {
              ...this.userData,
              ...formData,
            };
            this.persistSignup();
          })
          .fail(() => {
            flash(i18n.__('authenticate_invalid_form'), { ttl: 3000 });
          });
      });

      modal.eventSource.on('willHide.panel', () => {
        if (window.location.hash == '#authenticate') {
          window.location.hash = '';
        }
      });
    });

    this.modal = modal;

    modal.show();
  }

  signupBindOrganisationForm(modal) {
    let mappedCountries = [];
    const locale = this.preferred_locale.split('-');
    const language = locale[0];
    let countryCode = locale[1];

    this.remote
      .get(`countries/${language}`)
      .then((countries) => {
        mappedCountries = countries.map((country) => ({
          value: country.alpha2.toUpperCase(),
          label: country.name,
        }));
      })
      .catch(() => {
        mappedCountries = Object.keys(window.availableCountries).map((key) => ({
          value: key,
          label: window.availableCountries[key],
        }));
      })
      .finally(() => {
        const selectedCountryCode = mappedCountries.find((country) => country.value === countryCode) ? countryCode : '';

        DropDownRenderer.render(
          'countryCode',
          '#countryDropdownContainer',
          mappedCountries,
          selectedCountryCode,
          'countryLabel',
        );
      });

    $('body').on('keypress', (event) => {
      if (event.code === 'Space') {
        if ($('.lp--formCheckboxLabel').is(':focus')) {
          $('.lp--formCheckboxLabel input').trigger('click');
        }
        if ($('.lp--formRadioButtonLabel.school').is(':focus')) {
          $('.lp--formRadioButtonLabel.school input').trigger('click');
        }
        if ($('.lp--formRadioButtonLabel.enterprise').is(':focus')) {
          $('.lp--formRadioButtonLabel.enterprise input').trigger('click');
        }
        if ($('.lp--formRadioButtonLabel.other').is(':focus')) {
          $('.lp--formRadioButtonLabel.other input').trigger('click');
        }
      }
    });

    $('.lp--formRadioButtonLabel').on('click', () => {
      $('.organization-type-missing').addClass('hidden');
    });
  }

  signup(pendingToken) {
    this.pendingToken = pendingToken ? pendingToken : window.pending_token ? window.pending_token : null;
    if (this.pendingToken && this.pendingToken.preferred_locale) {
      this.preferred_locale = this.pendingToken.preferred_locale || i18n.getLocale();
      i18n.setLocale(this.preferred_locale);
    }

    this.preferred_locale = this.preferred_locale || i18n.getLocale();

    this.preselect_uuid = {
      uuid:
        pendingToken && pendingToken.preselect_uuid
          ? pendingToken.preselect_uuid
          : this.pendingToken
            ? this.pendingToken.uuid
            : null,
    };

    const isNewOrganization = this.pendingToken && this.pendingToken.organisation_type === 'new';

    const documentTag = `<a href="/assets/documents/${i18n.__('terms_and_conditions_document')}" target="_blank">`;

    let modal = new Modal(
      Handlebars.templates['signup_modal']({
        documentTag,
        isInvitation: !isNewOrganization,
        skipPassword: !!this.options.skipPassword,
        firstName: this.pendingToken && this.pendingToken.firstName,
        lastName: this.pendingToken && this.pendingToken.lastName,
        mobileNumber: this.pendingToken && this.pendingToken.mobileNumber,
      }),
      { class: 'lp--authenticationModal', disableEscape: true },
    );

    modal.eventSource.on('added.panel', () => {
      if (isNewOrganization) {
        this.signupBindOrganisationForm(modal);
      } else {
        $('body').on('keypress', (event) => {
          if (event.code === 'Space') {
            if ($('.lp--formCheckboxLabel').is(':focus')) {
              $('.lp--formCheckboxLabel input').trigger('click');
            }
          }
        });
      }

      this.togglePasswordVisibility(modal);

      this.panelWidth = modal.content.find('.lp--authenticateModalBody').outerWidth();

      this.form = modal.content.find('.lp--js-form').parsley();

      modal.content.find('.lp--js-nextSignupStep').on(Utils.interactionEvent, (ev) => {
        ev.preventDefault();
        const userData = modal.content.find('.lp--js-form').serializeObject();
        let hasErrors = false;
        if (isNewOrganization) {
          if (!userData.countryCode) {
            hasErrors = true;
            $('.country-code-missing').removeClass('hidden');
          } else {
            $('.country-code-missing').addClass('hidden');
          }
          if (!userData.organisation_type) {
            hasErrors = true;
            $('.organization-type-missing').removeClass('hidden');
          } else {
            $('.organization-type-missing').addClass('hidden');
          }
        }
        this.validateData(this.pendingToken.uuid, userData, hasErrors);
      });

      modal.eventSource.on('willHide.panel', () => {
        if (window.location.hash == '#authenticate') {
          window.location.hash = '';
        }
      });
    });
    this.modal = modal;

    modal.show();
  }

  validateData(token, user_data, hasErrors) {
    if (token && token.preferred_locale) {
      this.preferred_locale = token.preferred_locale || i18n.getLocale();
      i18n.setLocale(this.preferred_locale);
    }

    this.form
      .whenValidate()
      .done(() => {
        if (hasErrors) {
          flash(i18n.__('authenticate_invalid_form'), { ttl: 3000 });
        } else {
          return this.remote
            .get(`signup/invites/${token}`)
            .then((result) => {
              if (result.invites.length > 0) {
                this.accept_invites(token, user_data, result.invites);
              } else {
                this.userData = $.extend(true, user_data, {
                  token: token,
                  preferred_locale: this.preferred_locale,
                });
                this.persistSignup();
              }
            })
            .catch((err) => {
              console.error(err);
              flash(i18n.__('app_error_alert'), { ttl: 3000 });
            });
        }
      })
      .fail(() => {
        flash(i18n.__('authenticate_invalid_form'), { ttl: 3000 });
      });
  }

  accept_invites(token, user_data, invites) {
    if (this.pendingToken && this.pendingToken.preferred_locale) {
      this.preferred_locale = this.pendingToken.preferred_locale || i18n.getLocale();
      i18n.setLocale(this.preferred_locale);
    }
    const orgType = (this.pendingToken.orgType =
      (this.pendingToken && this.pendingToken.organisation && this.pendingToken.organisation.type) ||
      (window.code_token && window.code_token.organisationType));

    const userFullName = `${user_data.first_name} ${user_data.last_name}`;
    let modal = new Modal(
      Handlebars.templates['signup_join_invites']({
        userFullName,
        invites: invites,
        pendingToken: this.preselect_uuid ? this.preselect_uuid : this.pending_token,
        orgType,
      }),
      { class: 'lp--authenticationModal' },
    );
    this.modal = modal;

    this.form = this.modal.content.find('.lp--js-form').parsley();
    modal.content.find('.lp--js-persistSignup').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (ev) => {
      ev.preventDefault();
      var form_data = modal.content.find('.lp--js-form').serializeObject();
      return this.form
        .whenValidate()
        .done(() => {
          this.userData = {
            ...user_data,
            token,
            preferred_locale: this.preferred_locale,
            invites: form_data,
          };
          this.persistSignup();
        })
        .fail((err) => {
          console.log('failed', err);
        });
    });

    modal.content.find('.lp--invite').on('keypress', () => {
      $('input[type=checkbox]').trigger('click');
    });

    modal.content.find('input[type=checkbox]').on('change', () => {
      this.form
        .whenValid()
        .done(function () {
          modal.content.find('.lp--js-persistSignup').attr('disabled', false);
          modal.content.find('.lp--js-createLink').addClass('disabled');
        })
        .fail(function () {
          modal.content.find('.lp--js-persistSignup').attr('disabled', true);
          modal.content.find('.lp--js-createLink').removeClass('disabled');
          modal.content.find('.parsley-style-error').hide();
        });
    });

    modal.content.find('.lp--js-createLink').on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (ev) => {
      ev.preventDefault();
      if ($(ev.target).hasClass('disabled')) {
        modal.content.find('.parsley-style-error').show();
        return;
      }
      this.userData = {
        ...user_data,
        token,
        preferred_locale: this.preferred_locale,
      };
      this.signupCreateOrganisation();
    });

    modal.show();
  }

  persistSignup() {
    if (this.persisting_setup) {
      return;
    }

    this.persisting_setup = true;
    let data = this.userData;
    data.categories = (this.selectedCategories || []).map(function (category) {
      return category.id;
    });
    data.skipPassword = this.options.skipPassword;
    return this.remote
      .post('signup', data)
      .then(() => {
        this.persisting_setup = false;
        this.modal.hide();
        window.location.href = '/me';
      })
      .catch((err) => {
        console.error(err);
        this.persisting_setup = false;
        if (err.error == 'token_used') {
          flash(i18n.__(err.message), { ttl: 3000 });
        } else if (err.error == 'logged_in') {
          flash(err.message, { ttl: 3000 });
        } else if (err.code == 209) {
          flash(i18n.__('error_participation_email_filter_failed'), {
            ttl: 5000,
            type: 'status',
          });
        } else {
          flash(i18n.__('app_error_alert'), { ttl: 3000 });
        }
        throw err;
      });
  }

  static getAuthenticatedUser(options = {}) {
    let defaults = {
      onAuthenticated: true,
    };
    let extendedOptions = $.extend(true, {}, defaults, options);
    let userId = $('body').data('userUuid');

    let userRequest = new this(extendedOptions);

    return new Promise((resolve, reject) => {
      userRequest.resolve = resolve;
      userRequest.reject = reject;

      if (typeof userId != 'undefined' && userId.toString().length > 0) {
        resolve({ uuid: userId });
      } else {
        userRequest
          .login(extendedOptions)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject();
          });
      }
    });
  }

  static teamsSelectPath(options = {}) {
    let signupRequest = new this(options);
    return signupRequest.teamsSelectPath();
  }

  teamsSelectPath() {
    const that = this;
    const modal = new Modal(
      Handlebars.templates['teams_signup_select_path']({
        trialDays: window.loopsConfig.trialDays,
      }),
      { class: 'lp--authenticationModal', disableEscape: true },
    );

    modal.eventSource.on('added.panel', () => {
      modal.content
        .find('.lp--js-nextTeacher')
        .on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (event) => {
          event.preventDefault();
          that.options.skipPassword = true;
          that.signup(that.options.pendingToken);
        });

      modal.content
        .find('.lp--js-nextStudent')
        .on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (event) => {
          event.preventDefault();
          window.open('https://loopseducation.com', '_blank');
        });
    });

    this.modal = modal;
    modal.show();
  }

  static acceptInvite() {
    let acceptInviteRequest = new this();
    return acceptInviteRequest.acceptInvite();
  }

  acceptInvite() {
    this.pendingToken = window.pending_token ? window.pending_token : null;
    this.pendingToken.orgType =
      (this.pendingToken && this.pendingToken.organisation && this.pendingToken.organisation.type) ||
      (window.code_token && window.code_token.organisationType);
    let modal = new Modal(Handlebars.templates['invite_modal'](this.pendingToken), {
      class: 'lp--authenticationModal',
    });

    modal.eventSource.one('willHide.panel', () => {
      window.location.hash = '';
    });

    modal.eventSource.on('added.panel', () => {
      modal.content.find('.invitation-login-button').on(Utils.interactionEvent, (ev) => {
        ev.preventDefault();
        this.remote.get('logout').then((res) => {
          if (res.logged_out) {
            window.location.href = `/invite/token/${this.pendingToken.uuid}`;
          } else {
            window.location.href = '/logout';
          }
        });
      });

      const acceptButton = modal.content.find('.lp--js-accept');
      acceptButton.on('keypress ' + Utils.tapInteractionEvent + '.authenticate', (e) => {
        e.preventDefault();
        acceptButton.attr('disabled', true);

        this.remote
          .put(`invite/token/${this.pendingToken.uuid}/accept`)
          .then((res) => {
            window.location.hash = '';
            modal.hide();
            if (res.error) {
              flash(res.message);
            } else {
              if (window.location.href.indexOf('/me') >= 0) {
                if (this.pendingToken.type == 'circle_collaborator') {
                  window.location.href = '/my-loops#collaborator';
                } else {
                  window.location.reload(true);
                }
              }
            }
          })
          .catch((error) => {
            acceptButton.removeAttr('disabled');
          });
      });
    });

    modal.show();
  }

  static expiredAccountPopup(type) {
    new this().expiredAccountPopup(type);
  }

  expiredAccountPopup(type) {
    // render login modal if no user is logged in
    if (!document.querySelector('body').dataset.userUuid) {
      this.login();
    }
    ExpiredAccountPopupRenderer.render(window.loopsConfig.loopsSupport, type);
  }

  static linkEmail(userToken, source = '') {
    return new this().linkEmail(userToken, source);
  }

  linkEmail(userToken, source) {
    LinkEmailPopupRenderer.render(userToken, source);
  }
}
