import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 0;
`;

export const Button = styled.button`
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.standardBlackBackground};
  border-radius: 18px;
  border: 2px solid
    ${({ theme, isExperimental }) => (isExperimental ? theme.colors.behaviorYellowBack : theme.colors.iconButtonBorder)};
  color: ${({ theme, backgroundColor }) => (backgroundColor ? theme.colors.black : theme.colors.white)};
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding: 9px 15px 9px 35px;
  height: 36px;
  transition:
    border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  white-space: nowrap;
  position: relative;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    transition:
      border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.standardBlackBackground};
    border-color: ${({ theme, backgroundColor, isExperimental }) =>
      isExperimental
        ? theme.colors.behaviorYellow
        : backgroundColor
          ? theme.colors.white
          : theme.colors.iconButtonBorderHover};
    color: ${({ theme, backgroundColor }) => (backgroundColor ? theme.colors.black : theme.colors.white)};
    box-shadow: none;
  }

  :disabled {
    opacity: 0.5;
  }

  :after {
    content: ' ';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    margin-top: -3px;
    ${({ icon }) =>
      icon &&
      css`
        background: url(${icon}) center center no-repeat;
      `}
    ${({ iconSize = 20 }) => css`
      background-size: ${iconSize}px ${iconSize}px;
    `}
  }

  .show-mask & {
    z-index: -1;
  }
`;
