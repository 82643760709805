import { useEffect, useRef } from 'react';

const useKeyPress = (keys, handler, register) => {
  const eventListenerRef = useRef();

  useEffect(() => {
    eventListenerRef.current = (event) => {
      if (Array.isArray(keys) ? keys.includes(event.key) : keys === event.key) {
        handler && handler(event);
      }
    };
  }, [keys, handler]);

  useEffect(() => {
    const eventListener = (event) => {
      eventListenerRef.current(event);
    };

    if (register && handler) {
      window.addEventListener('keydown', eventListener);
    }

    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, [register]);
};

export default useKeyPress;
