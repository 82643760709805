import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import bodyScroll from 'body-scroll-toggle';
import useKeyPress from '../../hooks/useKeyPress';
import { useTransition } from '@react-spring/web';
import SecondaryButton from '../SecondaryButton';
import {
  PopupWrapper,
  PopupMask,
  PopupHeading,
  PopupBody,
  PopupContent,
  PopupTextBlock,
  PopupBox,
  PopupClose,
  PopupButtonPanel,
} from './styles';

const Popup = ({
  popupIsOpen,
  heading,
  id,
  description,
  buttonLabel,
  contentPanel,
  buttonPanel,
  onClose,
  onClick,
  onTransitionEnd,
  fullBody,
  rootElement = 'body',
  width,
  snapWidth = 0,
  overflowY = false,
  buttonDisabled = false,
  ...rest
}) => {
  const [elementsEnable, setElementsEnable] = useState(true);
  const hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
  const maskClass = ['show-mask'].concat(hasScrollbar ? 'scrollbar' : []);
  const body = document.querySelector('body');

  const mainElements = ['#headerContainer', '[role="main"]', '[role="region"]', '#footerContainer'];

  useKeyPress('Escape', onClose, popupIsOpen);

  useEffect(() => {
    if (elementsEnable) {
      enableElements();
    } else {
      disableElements();
    }
  }, [elementsEnable]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  const disableElements = () => {
    mainElements.forEach((selector) => {
      const region = document.querySelector(selector);
      if (!region) {
        return;
      }
      region.setAttribute('aria-hidden', true);
      const not = 'not([disabled], [tabindex="-1"])';
      const allTabableElements = region.querySelectorAll(
        `a[href]:${not}, button:${not}, input:${not}, textarea:${not}, select:${not}, details:${not}, [tabindex]:${not}`,
      );
      allTabableElements.forEach((element) => {
        element.classList.add('tab-disabled');
        element.setAttribute('tabindex', -1);
        element.setAttribute('disabled', true);
      });
      const allEnabledEditors = region.querySelectorAll('[contenteditable="true"]');
      allEnabledEditors.forEach((editor) => {
        editor.setAttribute('contenteditable', false);
        editor.setAttribute('disabled', true);
      });
    });
  };

  const enableElements = () => {
    mainElements.forEach((selector) => {
      const region = document.querySelector(selector);
      if (!region) {
        return;
      }
      region.removeAttribute('aria-hidden');
      const allTabDisabledElements = region.querySelectorAll('.tab-disabled');
      allTabDisabledElements.forEach((element) => {
        element.classList.remove('tab-disabled');
        element.setAttribute('tabindex', 0);
        element.removeAttribute('disabled');
      });
      const allDisabledEditors = region.querySelectorAll('[contenteditable][disabled]');
      allDisabledEditors.forEach((editor) => {
        editor.setAttribute('contenteditable', true);
        editor.removeAttribute('disabled');
      });
    });
  };

  const setup = () => {
    if (popupIsOpen && !body.dataset.popupId) {
      bodyScroll.disable();
      body.classList.add(...maskClass);
      body.dataset.popupId = id;
      setElementsEnable(false);
    }
  };

  const cleanup = () => {
    if (!popupIsOpen) {
      body.classList.remove('show-mask', 'scrollbar');
      delete body.dataset.popupId;
      setElementsEnable(true);
      bodyScroll.enable();
    }
  };

  const transitions = {
    onStart: () => {
      setup();
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onRest: () => {
      onTransitionEnd && onTransitionEnd();
      cleanup();
    },
  };

  const popupTransitions = useTransition(popupIsOpen, transitions);

  const modal = popupTransitions(
    (style, show) =>
      show && (
        <PopupWrapper role="dialog" aria-label={heading} style={style} id={id} $overflowY={overflowY} {...rest}>
          {fullBody ? (
            fullBody
          ) : (
            <PopupBox width={width} snapWidth={snapWidth} $overflowY={overflowY} className="popupBox">
              {onClose && (
                <PopupClose
                  tabIndex={0}
                  onClick={onClose}
                  onKeyPress={onClose}
                  role="button"
                  title={i18n.__('app_close')}
                  aria-label={i18n.__('app_close')}
                />
              )}
              <PopupBody width={width} snapWidth={snapWidth} $overflowY={overflowY}>
                {heading && (
                  <PopupHeading
                    capitalize={window.baseLocale === 'en-US'}
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                )}
                <PopupContent>
                  {description && <PopupTextBlock dangerouslySetInnerHTML={{ __html: description }} />}
                  {contentPanel}
                </PopupContent>
                <PopupButtonPanel singleButton={!!buttonLabel}>
                  {buttonLabel && <SecondaryButton text={buttonLabel} onClick={onClick} disabled={!!buttonDisabled} />}
                  {buttonPanel}
                </PopupButtonPanel>
              </PopupBody>
            </PopupBox>
          )}
          <PopupMask />
        </PopupWrapper>
      ),
  );

  return ReactDOM.createPortal(modal, document.querySelector(rootElement));
};

export default Popup;

Popup.propTypes = {
  popupIsOpen: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  contentPanel: PropTypes.object,
  buttonPanel: PropTypes.object,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  rootElement: PropTypes.string,
  width: PropTypes.number,
  snapWidth: PropTypes.number,
  overflowY: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  rest: PropTypes.object,
};
