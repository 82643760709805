import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import useNavigateWith from '../../hooks/useNavigateWithData';
import useLocalStorage from '../../hooks/useLocalStorage';
import useKeyPress from '../../hooks/useKeyPress';
import avatarSrcSelector from '../../components/ProfileAvatar/avatarSrcSelector';
import ProgressBar from '../../components/ProgressBar';
import InputCheckBox from '../../components/CheckBox';
import NotificationList from './NotificationList';
import OrganizationList from './OrganizationList';
import SkolonMenuButton from '../../components/SkolonMenuButton';
import TrialExtendPopup from '../../components/TrialExtendPopup';
import { isInTeamsChannel } from '../../utils/teams';
import PanelIcon from '../../icons/loop-panel.svg';
import {
  TopPanelWrapper,
  LogoPanelWrapper,
  LogoPanel,
  LoopPanel,
  LoopIcon,
  LoopName,
  LoopPanelIcon,
  ProgressWrapper,
  ProgressText,
  TeacherPanelButton,
  HamburgerMenuWrapper,
  MenuPanelWrapper,
  MenuPanel,
  ButtonMenuItem,
  OrganizationDropDownMenuItem,
  OrganizationName,
  NotificationDropDownMenuItem,
  AutoSaveStatus,
  AutoSaveWrapper,
} from './styles';

const TopPanel = (props) => {
  const {
    user,
    organisation,
    translations,
    loop = {},
    session,
    isEditor = false,
    isTeacherPanel = false,
    fileService,
    hasRouter = true,
    headerType,
    nodeCount,
    isInFrame = false,
    horizon = false,
    showLibrary = false,
    trialTimer,
    permissions,
    autoSave,
    hasActiveParticipants,
    flash,
    preLoadedOrganisations = null,
    preLoadedNotifications = null,
    features = {},
    skolon,
  } = props;

  if (!user) {
    const goHome = () => window.location.assign('/me');
    const gotoLoop = () => document.dispatchEvent(new Event('loopNameClicked'));
    return (
      <TopPanelWrapper horizon={horizon}>
        <LogoPanelWrapper>
          {loop.name ? (
            <LoopPanel tabIndex={0} onClick={gotoLoop} onKeyPress={gotoLoop}>
              <LoopIcon icon={avatarSrcSelector(fileService, loop.icon)} />
              <LoopName>{loop.name}</LoopName>
            </LoopPanel>
          ) : !isInFrame ? (
            <LogoPanel tabIndex={0} onClick={goHome} onKeyPress={goHome} role="img" title="Loops" />
          ) : (
            ''
          )}
        </LogoPanelWrapper>
      </TopPanelWrapper>
    );
  }

  const { first_name: firstName, user: nestedUser, auth_token: userToken, uuid } = user;
  const userName = nestedUser ? nestedUser.first_name : firstName;
  const userUuid = nestedUser ? nestedUser.uuid : uuid;

  const [notifications, setNotifications] = useState([]);
  const [isNotificationListSynced, setIsNotificationListSynced] = useState(false);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);

  const autoSaveKey = `autoSaveEnabled_${userUuid}_${loop.id || ''}`;
  const autoSaveIsConfigured = isEditor && autoSave.autoSaveBehaviors.includes('editor');
  const useAutoSave = autoSaveIsConfigured && !hasActiveParticipants;
  const showAutoSaveSwitch = autoSaveIsConfigured && hasActiveParticipants;
  const [autoSaveIsEnabled, setAutoSaveIsEnabled] = showAutoSaveSwitch
    ? useLocalStorage(autoSaveKey, useAutoSave)
    : useState(true);

  const [inTeamsChannel, setInTeamsChannel] = useState(false);

  useEffect(() => {
    const checkAutoSave = async () => {
      if (showAutoSaveSwitch && window.localStorage.getItem(autoSaveKey) === null) {
        setAutoSaveIsEnabled(false);
        document.dispatchEvent(new Event('showActiveParticipantsWarning'));
      }
      setInTeamsChannel(await isInTeamsChannel());
    };
    checkAutoSave();
  }, []);

  const { nav_bar } = translations;
  const { name: organizationName, icon: organizationIcon, uuid: organizationUuid } = organisation || {};
  const { navigateTo, navigateAndReload } = useNavigateWith('/me', hasRouter);
  const { getData } = useApi();
  const location = hasRouter ? useLocation() : window.location;

  const goHome = () => {
    const homeLink = JSON.parse(window.localStorage.getItem(`mainMenuOption_${userUuid}`)) || '/me';
    navigateTo(homeLink);
  };

  const gotoLoop = (id) => {
    const path = isEditor ? 'editor' : 'learn';
    navigateTo(`/${path}/${id}`);
  };

  const gotoLibrary = () => {
    navigateAndReload('/library');
  };

  const loopNameClicked = (id) => {
    if (isTeacherInSession && isTeacherPanel) {
      gotoLoop(id);
    } else {
      document.dispatchEvent(new Event('loopNameClicked'));
    }
  };

  const homeLocations = ['/me', '/my-loops', '/participant-overview', '/settings', '/settings/password'];

  useKeyPress(
    'Escape',
    () => {
      setIsMenuOpen(false);
    },
    isMenuOpen,
  );

  useKeyPress(
    'Escape',
    () => {
      setIsOrganizationMenuOpen(false);
    },
    isOrganizationMenuOpen,
  );

  useKeyPress(
    'Escape',
    () => {
      setIsNotificationMenuOpen(false);
    },
    isNotificationMenuOpen,
  );

  const getNotifications = async (refresh = false) => {
    if (preLoadedNotifications && !refresh) {
      setNotifications(preLoadedNotifications);
    } else {
      setIsNotificationsLoading(true);
      const response = await getData('/notifications');
      if (response.success) {
        setIsNotificationsLoading(false);
        setNotifications(response.body);
      } else {
        setNotifications([]);
      }
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (isNotificationMenuOpen && !isNotificationListSynced) {
      getNotifications(true);
      setIsNotificationListSynced(true);
    }
  }, [isNotificationMenuOpen]);

  useEffect(() => {
    const getOrganizationData = async () => {
      if (preLoadedOrganisations) {
        setOrganizationData(preLoadedOrganisations);
      } else {
        const response = await getData('/organisation');
        if (response.success) {
          setOrganizationData(response.body);
        } else {
          setOrganizationData({});
        }
      }
    };
    if (organisation) {
      getOrganizationData();
    }
  }, []);

  const hasUnreadNotifications = notifications.some((notification) => !notification.read && notification.tmpl_key);

  const toggleHamburgerMenu = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.querySelector('body').classList.add('modal-open');
    } else {
      document.querySelector('body').classList.remove('modal-open');
    }
  };

  const toggleOrganizationMenu = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsOrganizationMenuOpen(!isOrganizationMenuOpen);
  };

  const toggleNotificationMenu = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  const notificationRef = useRef();
  const notificationMenuRef = useRef();
  const organizationRef = useRef();
  const organizationMenuRef = useRef();

  const handleClickOutside = (ev) => {
    if (
      (!notificationRef.current || !notificationRef.current.contains(ev.target)) &&
      (!notificationMenuRef.current || notificationMenuRef.current.className !== ev.target.className)
    ) {
      setIsNotificationMenuOpen(false);
    }
    if (
      (!organizationRef.current || !organizationRef.current.contains(ev.target)) &&
      (!organizationMenuRef.current || organizationMenuRef.current.className !== ev.target.className)
    ) {
      setIsOrganizationMenuOpen(false);
    }
  };

  const handleNotificationRead = (ev) => {
    const { nodeId, studentUuid } = ev.detail;
    const notification = notifications.find((notification) => {
      const { node, actors = [] } = notification;
      let foundStudent = true;
      if (studentUuid) {
        foundStudent = actors.some((actor) => actor.uuid === studentUuid);
      }
      if (node && nodeId) {
        return foundStudent & (node.uuid === nodeId);
      }
      return false;
    });
    if (notification) {
      notification.read = true;
      setNotifications(
        notifications.map((oldNotification) =>
          oldNotification.id === notification.id ? notification : oldNotification,
        ),
      );
    }
  };

  const handleNotificationCreated = async () => {
    await getNotifications(true);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, []);

  useEffect(() => {
    document.addEventListener('notification.read', handleNotificationRead);
    return () => document.removeEventListener('notification.read', handleNotificationRead);
  }, []);

  useEffect(() => {
    document.addEventListener('notification.created', handleNotificationCreated);
    return () => document.removeEventListener('notification.created', handleNotificationCreated);
  }, []);

  useEffect(() => {
    document.addEventListener('progressUpdated', handleProgressUpdated);
    return () => document.removeEventListener('progressUpdated', handleProgressUpdated);
  }, []);

  useEffect(() => {
    document.addEventListener('setAutoSaveStatus', handleAutoSaveStatus);
    return () => document.removeEventListener('setAutoSaveStatus', handleAutoSaveStatus);
  }, []);

  const id = session ? session.id : loop ? loop.id : null;
  const url = `/learn/${id}`;
  const isTeacherInSession = session && ['owner', 'teacher'].includes(session.Participations[0].role);
  const isPupilInSession = session && session.Participations[0].role === 'pupil';
  const initProgress = (session && session.Participations[0].done_progress) || 0;
  const [progress, setProgress] = useState(initProgress);
  const [autoSaveStatus, setAutoSaveStatus] = useState('');

  const handleProgressUpdated = (ev) => {
    setProgress(ev.detail.done_progress);
  };

  const handleAutoSaveStatus = (ev) => {
    setAutoSaveStatus(ev.detail.autoSaveStatus);
  };

  const toggleAutoSave = () => {
    if (autoSaveIsConfigured) {
      setAutoSaveIsEnabled(!autoSaveIsEnabled);
      document.dispatchEvent(
        new CustomEvent('useAutoSave', {
          detail: { useAutoSave: !autoSaveIsEnabled },
        }),
      );
    }
  };

  const [isTrialExtendAllowed, setIsTrialExtendAllowed] = useState(false);
  const [trialExtendIsOpen, setTrialExtendPopupIsOpen] = useState(false);
  const autoShowTrialExtendKey = `autoShowTrialExtend_${user.uuid}_${organizationUuid}`;
  const [autoShowTrialExtend, setAutoShowTrialExtend] = useLocalStorage(autoShowTrialExtendKey, true);

  useEffect(() => {
    const { current_organisation_participation: currentParticipation, current_organisation: currentOrganization } =
      organizationData;
    const { is_owner: isOwner } = currentParticipation || {};
    const currentTrialDays = trialTimer;
    const isCurrentTrial = currentTrialDays >= 0;
    const { trial_extended: trialExtended } = currentOrganization || {};

    setIsTrialExtendAllowed(isOwner && isCurrentTrial && currentTrialDays === 0 && trialExtended === 0);
  }, [organizationData]);

  const setTrialExtendedState = (open, ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    setTrialExtendPopupIsOpen(open);
  };

  const openTrialExtend = (ev) => {
    setTrialExtendedState(true, ev);
  };

  const closeTrialExtend = (ev) => {
    setTrialExtendedState(false, ev);
  };

  useEffect(() => {
    if (autoShowTrialExtend && isTrialExtendAllowed) {
      openTrialExtend();
      setAutoShowTrialExtend(false);
    }
  }, [autoShowTrialExtend, isTrialExtendAllowed]);

  const sessionDisplayName = isTeacherPanel && session ? session.name : loop.name;
  const onLoopClick = () => loopNameClicked(id);
  const wrapperTooltip = isTeacherInSession && isTeacherPanel ? sessionDisplayName : i18n.__('app_show_loop_panel');
  const buttonTooltip = isTeacherPanel ? sessionDisplayName : translations.graph.quicklinks.teacher_panel;

  const logoPanel =
    headerType === 'loop' ? (
      <>
        <LoopPanel tabIndex={0} onClick={onLoopClick} onKeyPress={onLoopClick} title={wrapperTooltip}>
          <LoopIcon icon={avatarSrcSelector(fileService, loop.icon)} className="header-loop-icon" />
          <LoopName className="header-loop-name">{sessionDisplayName}</LoopName>
          {!isTeacherPanel && <LoopPanelIcon src={PanelIcon} title={wrapperTooltip} />}
          {isTeacherInSession && (
            <TeacherPanelButton
              title={buttonTooltip}
              aria-label={buttonTooltip}
              icon={'/assets/images/theme-2/icons/link_arrow_green.svg'}
              href={isTeacherPanel ? url : `${url}/teacher_panel`}
              onClick={(event) => event.stopPropagation()}
            />
          )}
          {isPupilInSession && (
            <ProgressWrapper>
              <ProgressBar progress={Math.round(progress * 100)} />
              <ProgressText>{`${Math.round(progress * nodeCount)}/${nodeCount}`}</ProgressText>
            </ProgressWrapper>
          )}
        </LoopPanel>
        {autoSaveIsConfigured && (
          <AutoSaveWrapper>
            {showAutoSaveSwitch && (
              <InputCheckBox
                id="autoSaveToggle"
                text={i18n.__('autosave')}
                defaultChecked={autoSaveIsEnabled}
                value={1}
                onChange={toggleAutoSave}
              />
            )}
            <AutoSaveStatus>{i18n.__(autoSaveStatus)}</AutoSaveStatus>
          </AutoSaveWrapper>
        )}
      </>
    ) : !isInFrame ? (
      <LogoPanel tabIndex={0} onClick={goHome} onKeyPress={goHome} role="img" title="Loops" />
    ) : (
      ''
    );

  const organizationMenuItem = organisation && (
    <OrganizationDropDownMenuItem
      ref={organizationMenuRef}
      icon={avatarSrcSelector(fileService, organizationIcon)}
      onClick={(ev) => toggleOrganizationMenu(ev)}
      onKeyPress={(ev) => toggleOrganizationMenu(ev)}
      selected={isOrganizationMenuOpen}
      tabIndex={0}
    >
      <OrganizationName>{organizationName}</OrganizationName>
      <OrganizationList
        ref={organizationRef}
        organizationData={organizationData}
        isOpen={isOrganizationMenuOpen}
        hasRouter={hasRouter}
        userToken={userToken}
        toggleOrganizationMenu={(ev) => toggleOrganizationMenu(ev)}
        fileService={fileService}
        trialDays={trialTimer}
        permissions={permissions}
        flash={flash}
        features={features}
        isTrialExtendAllowed={isTrialExtendAllowed}
        openTrialExtend={openTrialExtend}
      />
    </OrganizationDropDownMenuItem>
  );

  const libraryMenuItem = showLibrary && (
    <ButtonMenuItem
      tabIndex={0}
      onClick={gotoLibrary}
      onKeyPress={gotoLibrary}
      selected={location.pathname.startsWith('/library')}
      icon={'/assets/images/icons/library.svg'}
    >
      {nav_bar.library}
    </ButtonMenuItem>
  );

  const homeMenuItem = (
    <ButtonMenuItem
      tabIndex={0}
      onClick={goHome}
      onKeyPress={goHome}
      selected={homeLocations.includes(location.pathname)}
      icon={'/assets/images/theme-2/icons/home.svg'}
    >
      {userName}
    </ButtonMenuItem>
  );

  const notificationMenuItem = (
    <NotificationDropDownMenuItem
      ref={notificationMenuRef}
      hasUnreadNotifications={hasUnreadNotifications}
      onClick={(ev) => toggleNotificationMenu(ev)}
      onKeyPress={(ev) => toggleNotificationMenu(ev)}
      selected={isNotificationMenuOpen}
      tabIndex={0}
    >
      {nav_bar.notices}
      <NotificationList
        ref={notificationRef}
        notifications={notifications}
        isOpen={isNotificationMenuOpen}
        toggleNotificationMenu={(ev) => toggleNotificationMenu(ev)}
        hasRouter={hasRouter}
        userToken={userToken}
        isNotificationsLoading={isNotificationsLoading}
      />
    </NotificationDropDownMenuItem>
  );

  const shouldShowHamburgerMenu = window.innerWidth < 768;

  const menuPanel = (
    <MenuPanel isMenuOpen={isMenuOpen}>
      {!shouldShowHamburgerMenu && skolon && <SkolonMenuButton />}
      {organizationMenuItem}
      {libraryMenuItem}
      {homeMenuItem}
      {notificationMenuItem}
    </MenuPanel>
  );

  const menuPanelWrapper = !shouldShowHamburgerMenu ? (
    <MenuPanelWrapper>{menuPanel}</MenuPanelWrapper>
  ) : (
    <HamburgerMenuWrapper isMenuOpen={isMenuOpen} onClick={(ev) => toggleHamburgerMenu(ev)}>
      {menuPanel}
    </HamburgerMenuWrapper>
  );

  return (
    <TopPanelWrapper horizon={horizon} isMenuOpen={isMenuOpen || isNotificationMenuOpen || isOrganizationMenuOpen}>
      <TrialExtendPopup
        popupIsOpen={trialExtendIsOpen}
        userToken={userToken}
        onClose={closeTrialExtend}
        organizationUuid={organizationUuid}
      />
      <LogoPanelWrapper>{logoPanel}</LogoPanelWrapper>
      {!inTeamsChannel && menuPanelWrapper}
    </TopPanelWrapper>
  );
};

export default TopPanel;
