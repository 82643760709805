import React, { useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import Popup from '../Popup';

const ScreenRecorder = ({ recording, toggleRecorder, onStopRecording, onStartRecording, onReset, permissions }) => {
  const { audio, video } = permissions;
  const [showVideoInfo, setShowVideoInfo] = useState(false);

  const { startRecording, stopRecording, clearBlobUrl } = useReactMediaRecorder({
    audio,
    screen,
    onStop: (blobUrl, blob) => {
      onStopRecording(blob);
    },
    onStart: () => {
      onStartRecording();
    },
  });

  useEffect(() => {
    if (!video && toggleRecorder) {
      setShowVideoInfo(true);
    }
  }, [video, toggleRecorder]);

  useEffect(() => {
    if (recording) {
      stop();
    } else if (video) {
      clearBlobUrl();
      start();
    }
  }, [video, recording, toggleRecorder]);

  const start = () => {
    return startRecording();
  };

  const stop = () => {
    return stopRecording();
  };

  const reset = () => {
    onReset();
    setShowVideoInfo(false);
  };

  if (!video) {
    return (
      <Popup
        popupIsOpen={showVideoInfo}
        id="videoPopup"
        heading={i18n.__('video_permissions_required_heading')}
        description={i18n.__('video_permissions_required_body')}
        buttonLabel={i18n.__('app_close')}
        onClose={reset}
        onClick={reset}
      />
    );
  } else {
    return null;
  }
};

export default ScreenRecorder;
