import styled, { css } from 'styled-components';

export const ActivityEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActivityEditorSection = styled.div`
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const ActivityEditorSectionHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.buttonGreenFront};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.buttonGreenFront};
  border-radius: 10px 10px 0 0;
  align-items: normal;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 1;
  font-size: 15px;
  position: sticky;
  top: 65px;

  &.question {
    position: relative;
    top: initial;
  }
`;

export const EditorHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
`;

export const QuestionHeaderRow = styled(EditorHeaderRow)`
  width: 100%;
  align-items: start;
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const Question = styled.div`
  display: flex;
  margin-top: 20px;

  > div {
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const QuestionNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 22px;
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  display: flex;
  margin-right: 15px;
  justify-content: center;
  flex-shrink: 0;
  line-height: 23px;
`;

export const QuestionBody = styled.div`
  display: block;
  width: 100%;
  line-height: 24px;
  word-break: break-word;

  p {
    margin: 0;
  }

  .ql-editor {
    padding: 0;
  }
`;

export const EditorHeaderRowLeft = styled.div`
  align-items: ${({ isQuestion }) => (isQuestion ? 'start' : 'center')};
  display: flex;
  width: 100%;
  margin-right: 10px;
`;

export const HeaderUserName = styled.div`
  font-style: italic;
`;

export const EditorHeaderRowRight = styled.div`
  align-items: ${({ isQuestion }) => (isQuestion ? 'start' : 'center')};
  display: flex;
`;

export const ActivityEditor = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.buttonGreenFront};
  border-radius: 0 0 10px 10px;
  padding: 0;
  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
  display: flex;
`;

export const ActivityEditorArea = styled.div`
  border: none;
  word-break: break-word;

  :not(.lp--teacherPanel &) {
    width: 100%;
  }
`;

export const Editor = styled.div`
  word-wrap: break-word;
  box-sizing: border-box;
  height: 100%;
  line-height: 1.42;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  text-align: left;

  ${({ showStatusText }) =>
    showStatusText &&
    css`
      font-style: italic;
      opacity: 0.7;
    `}
`;

export const PreviewText = styled.div`
  font-size: 15px;
  font-style: italic;
  margin: 10px 0;
  opacity: 0.7;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  margin: 15px 0;

  button {
    border: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
    font-weight: bold;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      border-color: ${({ theme }) => theme.colors.black};
    }
  }
`;
