import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const PopupWrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.standardComponentBackground};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.hoverGrey};
  opacity: 1;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: 9;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 10px;
`;

export const Arrow = styled.div`
  border-color: ${({ theme }) => `${theme.colors.hoverGrey} ${theme.colors.transparent} ${theme.colors.transparent}`};
  bottom: -15px;
  left: 50%;
  right: 50%;
  margin-left: -7px;
  border-style: solid;
  border-width: 7px;
  height: 0;
  position: absolute;
  width: 0;
`;

export const MenuItem = styled.button`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
`;

export const Icon = styled.img`
  height: 13px;
  margin: 10px;
  width: 16px;
`;

export const Label = styled.span``;
