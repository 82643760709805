import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .quill {
    .ql-toolbar.ql-snow {
      padding: 8px 20px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
      border-top: 2px solid ${({ theme }) => theme.colors.buttonGreenFront};
      border-left: none;
      border-right: none;
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 126px;
      z-index: 1;
      display: none;

      ${({ showToolbar }) =>
        showToolbar &&
        css`
          display: block;
          margin: 0 -42px 15px;
          padding: 8px 42px;
        `}

      ${({ canToggleToolbar }) =>
        canToggleToolbar &&
        css`
          margin-left: 0;
          margin-right: 0;
          border: none;
          padding: 8px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.behaviorGreen};
        `}

      .ql-attachment:not(:hover),
      .ql-audio:not(:hover),
      .ql-dropbox:not(:hover),
      .ql-gdrive:not(:hover),
      .ql-onedrive:not(:hover),
      .ql-video:not(:hover) {
        opacity: 0.9;
      }

      .ql-textsize .ql-picker-label.ql-active .ql-fill {
        fill: ${({ theme }) => theme.colors.black};
      }

      .ql-textsize .ql-picker-label:hover .ql-fill {
        fill: ${({ theme }) => theme.colors.toolbarButtonBlue};
      }

      .ql-textsize.ql-icon-picker .ql-picker-item {
        width: auto;
        height: auto;
      }

      .ql-size-small {
        font-size: 0.75em;
      }

      .ql-size-normal {
        font-size: 1em;
      }

      .ql-size-large {
        font-size: 1.5em;
      }

      .ql-size-huge {
        font-size: 2.5em;
      }

      .ql-attachment .ql-stroke {
        stroke-width: 3px;
      }

      .ql-attachment svg {
        float: none;
      }

      button svg:not(:root) {
        overflow: visible;
      }

      .ql-picker-label {
        padding: 0 2px 0 6px;
      }

      .ql-insert:not(:hover) .ql-picker-label {
        opacity: 0.7;
      }

      .ql-screen_recorder {
        .ql-stroke {
          stroke-width: 8px;
        }

        &.recording .ql-fill {
          fill: ${({ theme }) => theme.colors.errorFlash} !important;
          filter: brightness(1.2);
        }
      }
    }

    &.question .ql-blank::before {
      left: 0;
    }

    &.question .ql-container {
      margin: 0;
    }

    .ql-container {
      border: none;

      .readability & {
        font-family: 'opendyslexic3regular';
      }

      &.ql-disabled {
        opacity: 0.7;
      }

      .ql-editor {
        padding: 0;

        ul,
        ol {
          margin-left: 0;
          padding-left: 0;
        }

        p {
          margin: 0 0 15px;
        }

        img {
          cursor: default;
        }

        &.ql-blank::before {
          color: ${({ theme }) => theme.colors.darkHorizonBackground};
          left: 0;
        }
      }

      .lp--activityEditorProgress {
        position: relative;
        width: 100%;
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: none;
        border: none;
        padding: 0px 30px;
        background-color: ${({ theme }) => theme.colors.black_8};

        .lp--activityEditorProgressLabel {
          width: 100%;
          color: ${({ theme }) => theme.colors.white};
          text-align: center;
          margin: 0px 0px 20px 0px;
          font-size: 22px;
        }

        .lp--activityEditorProgressBar {
          height: 30px;
          border-radius: 15px;
          width: 0px;
          background-color: ${({ theme }) => theme.colors.behaviorGreen};
        }

        .media-processing__icon {
          background-image: url(../../../../public/assets/images/loaders/loader.gif);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          height: 50px;
          width: 50px;
          margin: 20px auto;
        }
      }

      #emoji-palette .bem {
        margin: 5px;
      }

      iframe {
        max-width: 100% !important;
        width: 100%;
      }

      .ql-tooltip {
        background-color: ${({ theme }) => theme.colors.standardComponentBackground};
        border-radius: 25px;
        color: ${({ theme }) => theme.colors.white};
        min-width: 400px;
        left: -30px;

        &::before,
        a.ql-action::after,
        a.ql-action::after,
        a.ql-remove::before {
          content: none;
        }

        &.ql-editing::before {
          border-left: 6px solid ${({ theme }) => theme.colors.transparent};
          border-right: 6px solid ${({ theme }) => theme.colors.transparent};
          border-bottom: 6px solid ${({ theme }) => theme.colors.black_7};
          top: -5px;
          content: ' ';
          display: block;
          left: var(--arrowPosition);
          margin-left: -6px;
          position: absolute;
        }

        input[type='text'] {
          background: ${({ theme }) => theme.colors.transparent};
          border: none;
          color: ${({ theme }) => theme.colors.white};
          display: block;
          font-size: 13px;
          height: 100%;
          outline: none;
          padding: 10px 20px;
          width: 100%;

          .ql-preview {
            display: none;
          }

          ::placeholder {
            color: ${({ theme }) => theme.colors.white_6};
            font-style: italic;
          }
        }

        &[data-mode='formula'] {
          color: ${({ theme }) => theme.colors.black};
          left: 0 !important;
          min-height: 128px;
          min-width: 330px;
          z-index: 3;

          .ql-preview {
            display: none;
          }

          input[type='text'] {
            background: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.black};
            margin: 12px 0 6px 7px;
            min-height: 32px;
            padding: 0 10px;
            width: calc(100% - 14px);
          }

          a.ql-action,
          a.ql-remove {
            display: none;
          }

          .katex .sizing {
            font-size: initial !important;
          }
        }
      }

      &.ql-bubble .ql-tooltip {
        width: initial !important;
        min-width: initial !important;
      }
    }

    &.question .ql-container .ql-editor p {
      margin: 0;
    }

    &.question .ql-toolbar.ql-snow {
      position: relative;
      top: initial;
    }

    &.viewer .ql-container {
      margin: 15px;
    }
  }
`;
