import React from 'react';
import PropTypes from 'prop-types';
import HandIcon from '../../../../public/assets/images/theme-2/icons/hand.svg';
import StarIcon from '../../../../public/assets/images/theme-2/icons/asterisk-white.svg';
import { ToolbarWrapper, ToolbarButton, HeaderRequestFeedback } from '../styles';

const HeaderToolbar = ({ isEditable, report, showToolbar, setShowToolbar, toggleComments }) => {
  const toggleToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  const addAttachment = () => {
    document.dispatchEvent(
      new CustomEvent('addAttachment', { detail: { entityId: report.uuid, entityType: report.type } }),
    );
  };

  const uploadVideo = () => {
    document.dispatchEvent(
      new CustomEvent('uploadVideo', { detail: { entityId: report.uuid, entityType: report.type } }),
    );
  };

  const uploadAudio = () => {
    document.dispatchEvent(
      new CustomEvent('uploadAudio', { detail: { entityId: report.uuid, entityType: report.type } }),
    );
  };

  const defaultToggleComments = () => {
    document.dispatchEvent(
      new CustomEvent('toggleComments', { detail: { entityId: report.uuid, entityType: report.type } }),
    );
  };

  return (
    <ToolbarWrapper>
      {isEditable && (
        <>
          <ToolbarButton className="attachment" onClick={addAttachment} title={i18n.__('editor_tooltip_upload_file')} />
          <ToolbarButton className="video" onClick={uploadVideo} title={i18n.__('editor_tooltip_record_video')} />
          <ToolbarButton className="audio" onClick={uploadAudio} title={i18n.__('editor_tooltip_record_audio')} />
          <ToolbarButton
            className={`toggle ${showToolbar ? 'show' : ''}`}
            onClick={toggleToolbar}
            title={showToolbar ? i18n.__('editor_tooltip_hide_toolbar') : i18n.__('editor_tooltip_show_toolbar')}
          />
        </>
      )}
      <HeaderRequestFeedback
        isEditable={isEditable}
        onClick={toggleComments || defaultToggleComments}
        src={isEditable ? HandIcon : StarIcon}
      />
    </ToolbarWrapper>
  );
};

export default HeaderToolbar;

HeaderToolbar.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  report: PropTypes.object.isRequired,
  showToolbar: PropTypes.bool.isRequired,
  setShowToolbar: PropTypes.func.isRequired,
  toggleComments: PropTypes.func,
};
