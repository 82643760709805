const formulaOperators = [
  ['^{x}', '^{x}'],
  ['_{x}', '_{x}'],
  ['\\sqrt{x}', '\\sqrt{x}'],
  ['\\underline{\\underline{abc}}', '\\underline{\\underline{abc}}'],
  ['\\cdot', '\\cdot'],
  ['\\xrightarrow[xyz]{abc}', '\\xrightarrow[xyz]{abc}'],
  ['\\frac{x}{y}', '\\frac{x}{y}'],
  ['\\xtofrom{abc}', '\\xtofrom{abc}'],
  ['\\rho', '\\rho'],
  ['\\binom{n}{k}', '\\binom{n}{k}'],
];

export default formulaOperators;
