import styled, { css } from 'styled-components';

export const HeaderSection = styled.section`
  margin: 0px 0px 26px 0px;
  padding: 0 20px;

  @media ${({ theme }) => theme.sizes.tablet} {
    padding: 0px 42px;
  }
`;

export const TitleText = styled.h1`
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 36px !important;
  margin: 0;
  font-weight: 700;
  overflow: hidden;
  padding-bottom: 3px;
  text-overflow: ellipsis;
  text-transform: none !important;
`;

export const CommentsLink = styled.a`
  color: ${({ theme }) => theme.colors.black_9};
  margin: 15px 0px;
  display: flex;
  opacity: 0.7;
  position: relative;
  padding: 0px 0px 0px 30px;
  transition: 0.2s opacity ease-in-out;
  align-items: center;
  width: fit-content;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      &:before {
        margin: 0px 0px 0px auto;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 11px;
        background-color: ${({ theme }) => theme.colors.black};
        position: absolute;
        left: 0px;
        content: ' ';
        width: 22px;
        height: 22px;
        background-image: url(${src});
      }
    `}
`;

export const NodeToolbar = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
