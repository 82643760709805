import React, { useRef, useCallback, useEffect, useState } from 'react';
import useKeyPress from '../../hooks/useKeyPress';
import useClickOutside from '../../hooks/useClickOutside';
import { useTransition } from '@react-spring/web';
import { PopupWrapper, Container, MenuItem, Icon, Label, Arrow } from './styles';

const PopupMenu = ({ id, popupIsOpen, onClose, options = [] }) => {
  const [top, setTop] = useState(-10000);
  const [left, setLeft] = useState(-10000);

  const popup = useRef();
  const arrow = useRef();
  const close = useCallback(onClose, []);

  useKeyPress('Escape', onClose, popupIsOpen);
  useClickOutside(popup, close);

  useEffect(() => {
    if (popupIsOpen && popup && arrow) {
      const header = document.querySelector('#headerWrapper > section');
      const button = document.querySelector(`#${id}`);
      const buttonRect = button ? button.getBoundingClientRect() : {};
      const popupRect = popup.current ? popup.current.getBoundingClientRect() : {};
      const arrowRect = arrow.current ? arrow.current.getBoundingClientRect() : {};
      const headerRect = header ? header.getBoundingClientRect() : {};
      setLeft(buttonRect.left + buttonRect.width / 2 - popupRect.width / 2);
      setTop(buttonRect.top - popupRect.height - headerRect.height - arrowRect.height / 2);
    }
  }, [popupIsOpen]);

  const transitions = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  };

  const popupTransitions = useTransition(popupIsOpen, transitions);

  const menuItems = options.map((option) => {
    const { src, name, tag, onSelect } = option;
    return (
      <MenuItem key={tag} onClick={() => onSelect(tag)}>
        <Icon src={src} />
        <Label>{name}</Label>
      </MenuItem>
    );
  });

  return popupTransitions(
    (style, show) =>
      show && (
        <PopupWrapper style={style} ref={popup} top={top} left={left}>
          <Container>{menuItems}</Container>
          <Arrow ref={arrow} />
        </PopupWrapper>
      ),
  );
};

export default PopupMenu;
