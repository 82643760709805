function quillExtendedFormulas(dependencies = {}) {
  const Quill = dependencies.Quill || window.Quill;
  const katex = dependencies.katex || window.katex;

  return (quill, options = {}) => {
    let boundEvents = {};
    if (!Quill || !katex || !quill.options.modules.formula) {
      console.error('dependencies not met');
      return false;
    }

    const getTooltip = () => {
      return quill.container.getElementsByClassName('ql-tooltip')[0];
    };

    const getInput = () => {
      const tooltip = getTooltip();
      return tooltip.getElementsByTagName('input')[0];
    };

    const isAlphaNumeric = (str) => {
      return /^[A-Za-z0-9 ]+$/.test(str);
    };

    const insert = (str) => {
      const input = getInput();
      input.focus();
      let success = true;
      try {
        if (!document.execCommand('insertText', false, str)) {
          success = false;
        }
      } catch (error) {
        console.error(error);
        success = false;
      }

      return success;
    };

    const bindOnce = (element, eventName, handler) => {
      if (!boundEvents[eventName]) {
        boundEvents[eventName] = true;
        element.addEventListener(eventName, handler);
      }
    };

    const tooltipContent = () => {
      let preview = null;
      let documentLinkContainer = null;
      let operatorContainer = null;

      return {
        exists: () => {
          return document.querySelector('.quill-extended-formulas-operators');
        },
        render: () => {
          const input = getInput();
          input.focus();
          const textLength = input.value.length;
          if (textLength > 0) {
            input.setSelectionRange(textLength, textLength);
          }
          const selectNextVariable = (reversedDirection = false, index = null) => {
            if (index === null) {
              index = input.selectionStart;
            }

            let startIndex, endIndex;
            if (!reversedDirection) {
              startIndex = input.value.indexOf('{', index);
              if (startIndex > -1) {
                endIndex = input.value.indexOf('}', startIndex);
              }
            } else {
              startIndex = input.value.lastIndexOf('{', index);
              if (index > 1 && startIndex + 1 === index) {
                startIndex = input.value.lastIndexOf('{', index - 2);
              }
              if (startIndex > -1) {
                endIndex = input.value.indexOf('}', startIndex);
              }
            }

            if (startIndex > -1 && endIndex > -1) {
              const snippet = input.value.substr(startIndex + 1, endIndex - startIndex - 1);
              const selectionStart = startIndex + 1;
              const selectionEnd = isAlphaNumeric(snippet) ? endIndex : selectionStart;
              input.setSelectionRange(selectionStart, selectionEnd);
              return true;
            }
            return false;
          };

          bindOnce(input, 'keydown', (event) => {
            if (event.key === 'Tab') {
              const found = selectNextVariable(!!event.shiftKey);
              if (found || !!event.shiftKey) {
                event.preventDefault();
              }
            } else if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
              event.stopPropagation();
            }
          });

          if (options.preview && preview === null) {
            preview = document.createElement('div');
            preview.setAttribute('class', 'quill-extended-formulas-preview');

            const eventHandler = () => {
              katex.render(input.value, preview, {
                throwOnError: false,
              });
            };

            bindOnce(input, 'input', eventHandler);

            tooltip.appendChild(preview);
            eventHandler();
          }

          if (options.supportLink && documentLinkContainer === null) {
            const { text, url } = options.supportLink;
            documentLinkContainer = document.createElement('div');
            documentLinkContainer.setAttribute('class', 'quill-extended-formulas-support-document-link-container');

            const documentLink = document.createElement('a');
            documentLink.innerHTML = text;
            documentLink.setAttribute('href', '#');

            documentLink.onclick = (event) => {
              event.preventDefault();
              window.open(url, '_blank', 'height=1024,width=720');
            };
            documentLinkContainer.appendChild(documentLink);

            tooltip.appendChild(documentLinkContainer);
          }

          if (options.operators && operatorContainer === null) {
            operatorContainer = document.createElement('div');
            operatorContainer.setAttribute('class', 'quill-extended-formulas-operators');

            const createButton = (display, operator) => {
              let button = document.createElement('button');
              katex.render(display, button, {
                throwOnError: false,
              });
              button.setAttribute('aria-label', 'Formula button');
              button.setAttribute('type', 'button');
              button.setAttribute('data-value', operator);
              button.onclick = () => {
                const { selectionStart } = input;
                insert(operator);
                if (operator.indexOf('{') > -1) {
                  selectNextVariable(false, selectionStart);
                }
              };
              return button;
            };

            for (let [display, operator] of options.operators) {
              operatorContainer.appendChild(createButton(display, operator));
            }

            tooltip.appendChild(operatorContainer);
          }
        },
        destroy: () => {
          if (preview) {
            preview.remove();
            preview = null;
          }
          if (documentLinkContainer) {
            documentLinkContainer.remove();
            documentLinkContainer = null;
          }
          if (operatorContainer) {
            operatorContainer.remove();
            operatorContainer = null;
          }
        },
      };
    };

    const tooltip = getTooltip();
    const content = tooltipContent();

    const observer = new MutationObserver(() => {
      const isFormulaTooltipActive =
        !tooltip.classList.contains('ql-hidden') &&
        tooltip.attributes['data-mode'] &&
        tooltip.attributes['data-mode'].value === 'formula';
      if (isFormulaTooltipActive) {
        if (!content.exists()) {
          content.render();
        }
      } else {
        content.destroy();
      }
    });

    observer.observe(tooltip, {
      attributes: true,
      attributeFilter: ['class', 'data-mode'],
    });
  };
}

export default quillExtendedFormulas;
