import React, { useRef, useMemo, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill, { ImageAttribute } from '../../../app/assets/js/shared/components/quill.extended';
import flash from '../../../app/assets/js/shared/flash';
import Utils from '../../../app/assets/js/shared/utils';
import Panel from '../../../app/assets/js/shared/panels';
import { PickerPluginDrive } from '../../../app/assets/js/shared/picker';
import Recorder from '../Recorder';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import initializeIcons from './icons';
import clipboard from './clipboard';
import { hubToolbarButtons, nodeToolbarButtons } from './toolbar';
import formulaOperators from './formulaOperators';
import quillExtendedFormulas from '../../../app/assets/js/shared/components/quillExtendedFormulas';
import CanvasPopup from '../CanvasPopup';
import { ScreenRecorderRenderer } from '../../../app/assets/js/shared/reactLoader';
import katex from 'katex';
window.katex = katex;

const ContentEditor = (props) => {
  const {
    id = 'contentEditor',
    type = '',
    panelType,
    theme = 'snow',
    value = '',
    placeholder = '',
    onChange,
    integrations,
    fileService,
    disabled,
    canToggleToolbar = true,
    showToolbar,
    toolbar,
    ...rest
  } = props;
  const quillRef = useRef(null);
  const fileRef = useRef(null);
  const [recorderType, setRecorderType] = useState('');
  const [showCanvasPopup, setShowCanvasPopup] = useState(false);

  const toolbarContainer = toolbar ? toolbar : Utils.isNodePanelOpen ? nodeToolbarButtons : hubToolbarButtons;

  useEffect(() => {
    document.addEventListener('mediaprogress.updated', mediaProgressHandler);
    return () => document.removeEventListener('mediaprogress.updated', mediaProgressHandler);
  }, []);

  useEffect(() => {
    document.addEventListener('quill-embed-load', contentUpdated);
    document.addEventListener('contentUpdated', contentUpdated);
    return () => {
      document.removeEventListener('quill-embed-load', contentUpdated);
      document.removeEventListener('contentUpdated', contentUpdated);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('addAttachment', attachmentHandler);
    return () => document.removeEventListener('addAttachment', attachmentHandler);
  }, []);

  useEffect(() => {
    document.addEventListener('uploadVideo', videoHandler);
    return () => document.removeEventListener('uploadVideo', videoHandler);
  }, []);

  useEffect(() => {
    document.addEventListener('uploadAudio', audioHandler);
    return () => document.removeEventListener('uploadAudio', audioHandler);
  }, []);

  const getQuillEditor = () => quillRef && quillRef.current && quillRef.current.getEditor();

  const contentUpdated = () => {
    const Delta = Quill.import('delta');
    const quillEditor = getQuillEditor();
    quillEditor.updateContents(new Delta().retain(1), 'user');
  };

  const onFileChange = (ev) => {
    const file = ev.target.files[0];
    pasteAndDropHandler(file);
  };

  const appId = Utils.getOptions().cameraTag.appId;
  const userId = Utils.getCurrentUserData().uuid;

  const insertMediaHandler = () => {
    fileRef.current.click();
  };

  const attachmentHandler = () => {
    fileRef.current.click();
  };

  const dropboxHandler = () => {
    const options = {
      success: async (files) => {
        const { link, name } = files[0];
        await cloudFileHandler(link, name, AttachmentSource.Dropbox);
      },
      cancel: () => {},
      linkType: 'preview',
      multiselect: false,
    };
    window.Dropbox.choose(options);
  };

  const gdriveHandler = () => {
    const picker = new PickerPluginDrive(integrations.drive);
    picker.eventSource.on('file-success', async (ev, file) => {
      const { url, filename, accessToken, webViewLink, size, mime_type } = file;
      const options = { headers: { Authorization: `Bearer ${accessToken}` } };
      await cloudFileHandler(url, filename, AttachmentSource.Drive, size, mime_type, options, webViewLink);
    });
    picker.choose();
  };

  const onedriveHandler = () => {
    const options = {
      clientId: integrations.onedrive.client_id,
      action: 'query',
      multiselect: false,
      advanced: {
        queryParameters: 'select=id,name,file,@microsoft.graph.downloadUrl',
        redirectUri: integrations.onedrive.redirect_uri,
      },
      success: async (response) => {
        const item = response.value[0];
        const url = item['@microsoft.graph.downloadUrl'];
        const fileName = item.name;
        await cloudFileHandler(url, fileName, AttachmentSource.OneDrive);
      },
      cancel: () => {},
      error: () => {
        flash(i18n.__('app_error_alert'));
      },
    };
    window.OneDrive.open(options);
  };

  const recordHandler = (recorderType) => {
    $(document.getElementsByClassName('content-panel')[0]).scrollTop(0, 0);
    setRecorderType(recorderType);
  };

  const videoHandler = () => {
    recordHandler('video');
  };

  const audioHandler = () => {
    recordHandler('audio');
  };

  const dividerHandler = () => {
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.insertEmbed(range.index, 'divider', true, Quill.sources.USER);
    quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  const linkHandler = (value) => {
    const quillEditor = getQuillEditor();
    if (value) {
      const range = quillEditor.getSelection(true);
      if (range == null || range.length === 0) {
        flash(i18n.__('toolbar_button_link_no_text_selected'), { type: 'status' });
        return;
      }
      const { tooltip } = quillEditor.theme;
      tooltip.edit('link', '');
      tooltip.textbox.placeholder = location.origin;
    } else {
      quillEditor.format('link', false);
    }
  };

  const textSizeHandler = (value) => {
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.formatText(range.index, range.length, 'size', value, Quill.sources.USER);
  };

  const formulaHandler = () => {
    const quillEditor = getQuillEditor();
    const { tooltip } = quillEditor.theme;
    tooltip.edit('formula');
    setTooltipArrowPosition(quillEditor);
  };

  const insertHandler = function (value) {
    const quillEditor = getQuillEditor();
    quillEditor.options.modules.toolbar.handlers[value]();
  };

  const undoHandler = () => {
    const quillEditor = getQuillEditor();
    quillEditor.history.undo();
  };

  const canvasHandler = () => {
    setShowCanvasPopup(true);
  };

  const screenRecorderHandler = async () => {
    const onRecording = (recording) => {
      $('.ql-screen_recorder').toggleClass('recording', recording);
      const baseKey = 'toolbar_button_tooltip_screen_recorder';
      const tooltip = recording ? `${baseKey}_stop` : baseKey;
      $('.ql-screen_recorder').attr('title', i18n.__(tooltip));
    };
    const onRecordingDone = (blob) => {
      pasteAndDropHandler(new File([blob], 'mp4'));
    };
    const permissions = await Utils.getRecordingPermissions();
    ScreenRecorderRenderer.render(onRecording, onRecordingDone, permissions);
  };

  const keyboard = {
    bindings: {
      blur: {
        key: 27,
        handler: () => {
          const quillEditor = getQuillEditor();
          quillEditor.blur();
        },
      },
      handleEnter: {
        key: 13,
        handler: (range, context) => {
          const quillEditor = getQuillEditor();
          if (range.length > 0) {
            quillEditor.scroll.deleteAt(range.index, range.length); // So we do not trigger text-change
          }
          // Stop formatting as list when hitting enter for an empty item
          if (!context.prefix && context.format.list) {
            delete context.format.list;
            quillEditor.removeFormat(range.index, 1);
          }
          const lineFormats = Object.keys(context.format).reduce((lineFormats, format) => {
            const Parchment = Quill.import('parchment');
            if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
              lineFormats[format] = context.format[format];
            }
            return lineFormats;
          }, {});
          const previousChar = quillEditor.getText(range.index - 1, 1);
          // Earlier scroll.deleteAt might have messed up our selection,
          // so insertText's built in selection preservation is not reliable
          quillEditor.insertText(range.index, '\n', lineFormats, Quill.sources.USER);
          if (previousChar === '' || previousChar === '\n') {
            quillEditor.setSelection(range.index + 2, Quill.sources.SILENT);
          } else {
            quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
          }
          Object.keys(context.format).forEach((name) => {
            if (lineFormats[name] !== null || Array.isArray(context.format[name]) || name === 'link') {
              return;
            }
            quillEditor.format(name, context.format[name], Quill.sources.USER);
          });
        },
      },
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: (range) => {
          const quillEditor = getQuillEditor();
          const currentLeaf = quillEditor.getLeaf(range.index)[0];
          const nextLeaf = quillEditor.getLeaf(range.index + 1)[0];

          quillEditor.insertEmbed(range.index, 'break', true, 'user');

          // Insert a second break if:
          // At the end of the editor, OR next leaf has a different parent (<p>)
          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
            quillEditor.insertEmbed(range.index, 'break', true, 'user');
          }

          // Now that we've inserted a line break, move the cursor forward
          quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
        },
      },
      clearNonBreakingSpace: {
        key: 32,
        shiftKey: true,
        ctrlKey: true,
        handler: () => {
          const quillEditor = getQuillEditor();
          const html = quillEditor.container.innerHTML;
          if (html.indexOf('&nbsp;') > -1) {
            const clearedHtml = html.replace(/&nbsp;/g, ' ');
            quillEditor.container.innerHTML = clearedHtml;
          }
        },
      },
    },
  };

  if (theme === 'bubble') {
    keyboard.bindings.tab = {
      key: 9,
      handler: () => {
        return true;
      },
    };
  }

  const initializeProgressBlot = ({ size = 0, blot }) => {
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    const key = Date.now();
    const progressBlot = blot || insertBlot(quillEditor, 'progress', key, range);
    const onProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      const fileSize = total || parseInt(size);
      const percentDone = Math.floor((loaded / fileSize) * 100);
      const progress = percentDone > 100 ? 100 : percentDone;
      progressBlot.find('.lp--activityEditorProgressLabel').text(`${progress}%`);
      progressBlot.find('.lp--activityEditorProgressBar').width(`${progress}%`);
    };
    return { range, key, blot: progressBlot, onProgress };
  };

  const cloudFileHandler = async (url, fileName, source, size, mime_type, options, webViewLink) => {
    let fileUrl = webViewLink;
    let mimeType = mime_type;
    let key, blot, onProgress, onUploadProgress;
    if (url) {
      ({ blot, key, onProgress } = initializeProgressBlot({ size }));
      const file = await Utils.downloadFile(url, options, onProgress, mime_type);
      ({ onProgress: onUploadProgress } = initializeProgressBlot({ blot }));
      const data = await Utils.uploadFileToArchivist({ file, onUploadProgress, fileName, mime_type });
      fileUrl = fileService.url + data.filename;
      mimeType = data.filemime;
    }
    embedUploadedFile({
      url: fileUrl,
      fileName,
      source,
      key,
      mimeType,
    });
  };

  const pasteAndDropHandler = (file, base64Data) => {
    if (!(file instanceof File)) {
      return;
    }

    const { range, key, blot, onProgress: onUploadProgress } = initializeProgressBlot({});
    const uploadFile = async () => {
      const quillEditor = getQuillEditor();
      const data = await Utils.uploadFileToArchivist({ file, onUploadProgress });

      if (data) {
        const { filemime, filename, original_filename, oembed } = data;
        embedUploadedFile({
          url: fileService.url + filename,
          uuid: filename.split('.')[0],
          fileName: original_filename,
          mimeType: filemime,
          oembed,
          key,
          source: AttachmentSource.DropPaste,
        });
      } else if (base64Data) {
        const Delta = Quill.import('delta');
        quillEditor.updateContents(
          new Delta().retain(range.index).delete(range.length).insert({ image: base64Data }),
          Quill.sources.USER,
        );
        quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
      }
    };
    setTimeout(() => uploadFile(), 200);
  };

  const addClipBoardMatcher = (quillEditor) => {
    quillEditor.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
      if (node.data.match(/^http(s)*\:\/\//)) {
        if (node.data.match(/jp(e)*g|gif|png|webp/)) {
          return { ops: [{ insert: { image: node.data } }, { insert: '\n' }] };
        } else {
          return { ops: [{ insert: { oembed: { url: node.data, newEmbed: true } } }] };
        }
      } else if (node.data.match(/<iframe.*?s*src="http(.*?)".*?<\/iframe>/)) {
        return { ops: [{ insert: { iframe: node.data } }] };
      } else {
        delta.ops = delta.ops.map((el) => {
          if (el.insert && el.insert.normalize) {
            el.insert = el.insert.normalize();
          }
          return el;
        });
        return delta;
      }
    });
  };

  const AttachmentSource = {
    Upload: 'upload',
    Drive: 'drive',
    Dropbox: 'dropbox',
    Webcam: 'webcam',
    Microphone: 'microphone',
    OneDrive: 'onedrive',
    DropPaste: 'drop_paste',
  };

  const removeBlot = (quillEditor, blotInstance) => {
    const blot = blotInstance && Quill.find(blotInstance);
    const index = blot && quillEditor.getIndex(blot);
    blot &&
      quillEditor.deleteText({
        index,
        length: 1,
        source: Quill.sources.SILENT,
      });
  };

  const insertBlot = (quillEditor, blotType, key, range, textKey) => {
    quillEditor.insertEmbed(range.index, blotType, key, Quill.sources.SILENT);
    if (textKey) {
      $(`#${blotType}-progress-${key} .lp--activityEditorProgressLabel`).html(i18n.__(textKey));
    }
    return blotType === 'progress' ? $(`#upload-progress-${key}`) : $(`#${blotType}-progress-${key}`);
  };

  const isLinkedFile = (source) =>
    source === AttachmentSource.Dropbox || source === AttachmentSource.Drive || source === AttachmentSource.OneDrive;

  const usesProgressBlot = (source) => !isLinkedFile(source) && source !== AttachmentSource.DropPaste;

  const embedFile = (quillEditor, range, url, fileName) => {
    const embedType = Utils.fileIsEmbedable(url) ? 'oembed' : 'file';
    quillEditor.insertEmbed(range.index, embedType, { url, fileName }, Quill.sources.USER);
  };

  const embedUploadedFile = (data) => {
    const quillEditor = getQuillEditor();
    const { url, uuid, fileName, mimeType, oembed, source, key } = data;

    const range = quillEditor.getSelection(true) || { index: 0, length: 0 };
    const index = range.index;
    const progress = key && document.querySelector(`#upload-progress-${key}, #conversion-progress-${key}`);
    if (!progress && usesProgressBlot(source)) {
      return;
    }
    removeBlot(quillEditor, progress);

    if (mimeType.match(/video/)) {
      const locale = $('body').data('user-base-locale');
      const localizedUrl = `${oembed}/${locale}`;
      quillEditor.insertEmbed(index, 'oembed', { uuid, url: localizedUrl }, Quill.sources.USER);
    } else if (mimeType.match(/image/)) {
      const image = new Image();
      image.onload = () => {
        quillEditor.insertEmbed(index, 'image', url, Quill.sources.USER);
      };
      image.onerror = () => {
        embedFile(quillEditor, range, url, fileName);
      };
      image.src = url;
    } else if (mimeType.match(/audio/)) {
      quillEditor.insertEmbed(index, 'audio', { uuid }, Quill.sources.USER);
    } else {
      embedFile(quillEditor, range, url, fileName);
    }

    quillEditor.insertText(quillEditor.getLength() + 1, '\n', Quill.sources.API);
    quillEditor.setSelection(quillEditor.getLength() + 1, Quill.sources.API);
  };

  const addLinkClickHandlers = (quillEditor) => {
    const root = $(quillEditor.root);
    root.find('a').off('click');
    root.find('a').on(Utils.interactionEvent, (ev) => {
      if ($(ev.target).closest('.lp--activityEditorLinkBox, .file').length > 0) {
        return;
      }
      const { tooltip } = quillEditor.theme;
      const url = root.parent().find('.ql-preview').attr('href');
      tooltip.edit('link', url);
      tooltip.textbox.placeholder = location.origin;
      root.parent().find('.ql-editing input')[0].setSelectionRange(url.length, url.length);
      setTooltipArrowPosition(quillEditor);
    });
  };

  const addTooltips = (quillEditor) => {
    quillEditor.options.modules.toolbar.container.forEach((container) => {
      container.forEach((item) => {
        const key = Object.keys(item)[0];
        const keyPrefix = 'toolbar_button_tooltip_';
        if (typeof item === 'object' && typeof item[key] !== 'object') {
          $(`.ql-toolbar button.ql-${key}[value="${item[key]}"]`).attr(
            'title',
            i18n.__(`${keyPrefix}${key}_${item[key]}`),
          );
        } else if (typeof item === 'object' && typeof item[key] === 'object') {
          $(`.ql-toolbar span.ql-${key}`).attr('title', i18n.__(`${keyPrefix}${key}`));
        } else {
          $(`.ql-toolbar button.ql-${item}`).attr('title', i18n.__(`${keyPrefix}${item}`));
          if (!navigator.appVersion.includes('Win')) {
            const tooltip = $(`button.ql-${item}[title*="Ctrl"]`).attr('title');
            if (tooltip) {
              $(`button.ql-${item}`).attr('title', tooltip.replace('Ctrl', 'Cmd'));
            }
          }
        }
      });
    });
  };

  const addTextSizeCustomizations = (quillEditor) => {
    const textSizeButton = $(quillEditor.container).parent().find('.ql-textsize');
    textSizeButton.find('.ql-picker-item').each((index, item) => {
      const size = item.dataset.value ? item.dataset.value : 'normal';
      item.textContent = i18n.__(`toolbar_button_tooltip_textsize_${size}`);
      item.classList.add(`ql-size-${size}`);
    });
    const toolbarIcons = Quill.import('ui/icons');
    textSizeButton.addClass('ql-icon-picker');
    textSizeButton.find('.ql-picker-label').html(toolbarIcons['textsize']);
    textSizeButton.on(Utils.interactionEvent, () => {
      const range = quillEditor.getSelection(true);
      const rangeFormat = quillEditor.getFormat(range.index, range.length);
      if (rangeFormat && rangeFormat['size']) {
        const sizeClass = `ql-size-${rangeFormat['size']}`;
        textSizeButton.find('.ql-picker-item').removeClass('ql-selected');
        textSizeButton.find(`.ql-picker-item.${sizeClass}`).addClass('ql-selected');
      } else {
        textSizeButton.find('.ql-picker-item.ql-size-normal').addClass('ql-selected');
      }
    });
  };

  const addInsertPickerCustomizations = (quillEditor, toolbarIcons) => {
    const insertPicker = $(quillEditor.container).parent().find('.ql-insert');
    insertPicker.addClass('ql-icon-picker');
    insertPicker.find('.ql-picker-label').html(toolbarIcons['insert']).removeClass('ql-active');
    insertPicker.find('.ql-picker-item').each((index, item) => {
      $(item).attr('title', i18n.__(`toolbar_button_tooltip_${item.dataset.value}`));
      $(item).addClass(`ql-${item.dataset.value}`);
      item.innerHTML = toolbarIcons[item.dataset.value];
    });
    $(quillEditor.container).parent().find('.ql-picker-label').removeAttr('aria-hidden');
  };

  const setTooltipArrowPosition = (quillEditor) => {
    const tooltip = $('.ql-tooltip.ql-editing');
    if (tooltip.length > 0) {
      const range = quillEditor.getSelection(true);
      const bounds = quillEditor.getBounds(range.index, range.length);
      const tootipLeft = tooltip.position() ? tooltip.position().left : 0;
      const position = bounds.width / 2 + bounds.left + tootipLeft / 2;
      tooltip.css('--arrowPosition', `${position}px`);
      if (position < 20) {
        const newPosition = Math.max(position + 23, 23);
        tooltip.css({ transform: 'translateX(-20px)', '--arrowPosition': `${newPosition}px` });
      }
    }
  };

  const getPlaceholderText = () => {
    if (window.editor) {
      if (panelType === Panel.CIRCLE || panelType === Panel.HUB) {
        return placeholder;
      } else {
        return i18n.__('default_texteditor_placeholder');
      }
    }
    if (type === 'evaluation') {
      return placeholder;
    } else if (type === 'task' || type === 'written_quiz') {
      return i18n.__('task_texteditor_placeholder');
    } else if (type === 'logbook') {
      return i18n.__('logbook_texteditor_placeholder');
    } else if (type === 'group') {
      return i18n.__('grouptask_texteditor_placeholder');
    } else if (type === 'noticeboard') {
      return i18n.__('noticeboard_texteditor_placeholder');
    } else {
      return i18n.__('default_texteditor_placeholder');
    }
  };

  const mediaProgressHandler = (ev) => {
    const quillEditor = getQuillEditor();
    const { key, fileName } = ev.detail.userMetadata;
    const oembed = $(`.oembed[data-uuid="${key}"]`);
    if (ev.detail.status === 'ERROR') {
      flash(i18n.__('media_conversion_failed'));
      oembed.remove();
      const range = quillEditor.getSelection(true);
      const fileExtension = Utils.GetFileExtension(fileName);
      embedFile(quillEditor, range, `${fileService.url}${key}.${fileExtension}`, fileName);
    } else {
      const iframe = oembed.find('iframe');
      const reloadIframe = () => iframe.attr('src', iframe.attr('src'));
      if (iframe.length > 0) {
        window.addEventListener('message', (ev) => {
          const progress = ev['data'];
          if (progress === '100') {
            reloadIframe();
          }
        });
        reloadIframe();
      }
    }
  };

  const addAriaAttributes = () => {
    const hiddenElements = $('.ql-toolbar > span > span > span');
    hiddenElements.attr('aria-hidden', true);
  };

  const addExtendedFormulas = (quillEditor) => {
    const options = Utils.getOptions();
    const enableExtendedFormulas = quillExtendedFormulas();
    enableExtendedFormulas(quillEditor, {
      operators: options.organization.formula_operators || formulaOperators,
      supportLink: {
        text: i18n.__('editor_katex_link'),
        url: options.katexUrl,
      },
      preview: true,
    });
  };

  const initializeEditor = (toolbarIcons) => {
    const quillEditor = getQuillEditor();
    if (quillEditor) {
      const quillParent = $(quillEditor.container).parent();

      addInsertPickerCustomizations(quillEditor, toolbarIcons);
      addTextSizeCustomizations(quillEditor);
      addLinkClickHandlers(quillEditor);
      addTooltips(quillEditor);
      addExtendedFormulas(quillEditor);
      addAriaAttributes();

      setTimeout(() => addClipBoardMatcher(quillEditor), 1000);

      quillParent.find('.ql-color-picker').on(Utils.interactionEvent, () => {
        quillEditor.getSelection(true);
      });

      quillParent.find('.ql-align').on('mouseup', () => {
        quillEditor.getSelection(true);
      });

      quillParent.find('.ql-link').on(Utils.interactionEvent, () => {
        setTooltipArrowPosition(quillEditor);
      });

      quillParent.find('.ql-emoji').on(Utils.interactionEvent, () => {
        $('.tool-container').hide();
        const observer = new MutationObserver(() => {
          quillEditor.setSelection(quillEditor.getLength(), Quill.sources.SILENT);
          quillEditor.focus();
          observer.disconnect();
        });

        observer.observe($('.ql-editor')[0], {
          childList: true,
          subtree: true,
        });
      });
    }
  };

  const toolbarIcons = initializeIcons();
  initializeEditor(toolbarIcons);

  const modules = {
    clipboard,
    keyboard,
    formula: true,
    toolbar: {
      container: toolbarContainer,
      handlers: {
        image: insertMediaHandler,
        divider: dividerHandler,
        textsize: textSizeHandler,
        link: linkHandler,
        video: videoHandler,
        audio: audioHandler,
        attachment: attachmentHandler,
        dropbox: dropboxHandler,
        gdrive: gdriveHandler,
        onedrive: onedriveHandler,
        formula: formulaHandler,
        insert: insertHandler,
        undo: undoHandler,
        canvas: canvasHandler,
        screen_recorder: screenRecorderHandler,
      },
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar', ImageAttribute],
    },
    'emoji-toolbar': true,
    fileDrop: {
      pasteAndDropHandler,
    },
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
  };

  const recordingPublished = (recording) => {
    $('.recorder').hide();
    const quillEditor = getQuillEditor();
    const range = quillEditor.getSelection(true);
    if (recording.source == AttachmentSource.Webcam) {
      quillEditor.insertEmbed(range.index, 'webcam', recording, Quill.sources.USER);
    } else if (recording.source == AttachmentSource.Microphone) {
      quillEditor.insertEmbed(range.index, 'microphone', recording, Quill.sources.USER);
    }
    quillEditor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  const recordingProcessed = (recording) => {
    toggleRecorder('');
    let mediaSelector = recording.type === 'Video' ? '.webcam' : '.microphone';
    mediaSelector += `[data-uuid="${recording.uuid}"]`;
    if ($(mediaSelector).length > 0) {
      $(mediaSelector).removeAttr('data-state').children(recording.type).removeAttr('poster')[0].load();
    }
  };

  const onSaveCanvas = (svg) => {
    setShowCanvasPopup(false);
    const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
    pasteAndDropHandler(new File([blob], 'svg'));
  };

  let className = rest.className ? rest.className + ' ' : '';
  if (window.viewer) {
    className += 'viewer';
  } else if (window.editor) {
    className += 'editor';
  } else if (windwow.tp) {
    className += 'tp';
  }
  delete rest.className;

  return (
    <Wrapper showToolbar={!disabled && showToolbar} canToggleToolbar={!disabled && canToggleToolbar}>
      <ReactQuill
        id={id}
        autoFocus={true}
        name={id}
        ref={quillRef}
        theme={theme}
        placeholder={getPlaceholderText()}
        value={value}
        onChange={(value, delta, source) => {
          source === 'user' && onChange(value, id);
        }}
        modules={useMemo(() => modules, [id])}
        readOnly={disabled}
        className={className}
        {...rest}
      />
      <input
        id={`file_${id}`}
        onChange={onFileChange}
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        accept="*/*"
      />
      {recorderType && (
        <Recorder
          appId={appId}
          userId={userId}
          type={recorderType}
          onClose={() => setRecorderType('')}
          onRecordingPublished={recordingPublished}
          onRecordingProcessed={recordingProcessed}
          AttachmentSource={AttachmentSource}
        />
      )}
      <CanvasPopup
        popupIsOpen={showCanvasPopup}
        onSave={(svg) => onSaveCanvas(svg)}
        onClose={() => setShowCanvasPopup(false)}
      />
    </Wrapper>
  );
};

export default ContentEditor;

ContentEditor.propTypes = {
  type: PropTypes.string.isRequired,
  panelType: PropTypes.number.isRequired,
  theme: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  integrations: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  showToolbar: PropTypes.bool,
  canToggleToolbar: PropTypes.bool,
  rest: PropTypes.object,
};
