import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentEditor from '../../ContentEditor';
import NodeActivityEditor from '../../NodeActivityEditor';
import HeaderToolbar from './HeaderToolbar';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import { ViewSection, HeaderUserImage, HeaderUserName, AutoSaveStatus } from '../styles';
import { EditorHeaderRowLeft, EditorHeaderRowRight } from '../../NodeActivityEditor/styles';

const TaskContentViewer = ({
  type,
  report,
  isEditable,
  saveReport,
  user,
  fileService,
  integrations,
  panel,
  autoSaveStatus,
  isTeacher,
}) => {
  const [showToolbar, setShowToolbar] = useState(false);
  const { full_name, image = {} } = user || {};

  const onChangeReport = (value) => {
    saveReport({ ...report, body: value, draft: true });
  };

  const submitReport = () => {
    saveReport({ ...report, state: 'done', draft: false });
  };

  const revokeReport = () => {
    saveReport({ ...report, state: 'active', draft: true });
  };

  if (!report) {
    return null;
  }

  const headerPanel = (
    <>
      <EditorHeaderRowLeft>
        <HeaderUserImage src={image._url && `${fileService.url}60x60/${image._url}`} />
        <HeaderUserName>{full_name}</HeaderUserName>
      </EditorHeaderRowLeft>
      <EditorHeaderRowRight>
        <HeaderToolbar
          isEditable={isEditable}
          report={report}
          showToolbar={showToolbar}
          setShowToolbar={setShowToolbar}
        />
      </EditorHeaderRowRight>
    </>
  );

  const editorPanel = (
    <ContentEditor
      type={type}
      panelType={panel.type}
      aria-label={i18n.__('placeholder_description')}
      placeholder={i18n.__('placeholder_description')}
      value={report.body || ''}
      onChange={(value) => {
        isEditable && onChangeReport(value);
      }}
      integrations={integrations}
      fileService={fileService}
      disabled={!isEditable}
      showToolbar={showToolbar}
    />
  );

  const buttonPanel = (
    <>
      <AutoSaveStatus>{autoSaveStatus['contentEditor']}</AutoSaveStatus>
      {isEditable ? (
        <SecondaryButton text={i18n.__('app_handin')} onClick={submitReport} disabled={isTeacher} />
      ) : (
        <PrimaryButton text={i18n.__('app_edit')} onClick={revokeReport} />
      )}
    </>
  );

  return (
    <ViewSection>
      <NodeActivityEditor type={type} headerPanel={headerPanel} editorPanel={editorPanel} buttonPanel={buttonPanel} />
    </ViewSection>
  );
};

export default TaskContentViewer;

TaskContentViewer.propTypes = {
  type: PropTypes.string.isRequired,
  report: PropTypes.object,
  isEditable: PropTypes.bool.isRequired,
  saveReport: PropTypes.func.isRequired,
  user: PropTypes.object,
  fileService: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  integrations: PropTypes.object.isRequired,
  autoSaveStatus: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
};
