import React from 'react';
import PropTypes from 'prop-types';
import WrittenQuizEditorHeader from './nodes/WrittenQuizEditorHeader';

import {
  ActivityEditorContainer,
  ActivityEditorSection,
  ActivityEditorSectionHeader,
  EditorHeaderRow,
  ActivityEditor,
  ActivityEditorArea,
  ButtonWrapper,
  Editor,
  PreviewText,
} from './styles';

const NodeActivityEditor = ({
  type,
  index = 0,
  headerPanel,
  headerRightPanel,
  headerText,
  editorPanel,
  editorText,
  sneakPeakPanel,
  buttonPanel,
  ...rest
}) => {
  const headerSection = () => {
    switch (type) {
      case 'task':
        return <EditorHeaderRow>{headerPanel}</EditorHeaderRow>;
      case 'written_quiz':
        return (
          <WrittenQuizEditorHeader
            index={index}
            headerPanel={headerPanel}
            headerRightPanel={headerRightPanel}
            headerText={headerText}
          />
        );
      default:
        return <EditorHeaderRow />;
    }
  };

  return (
    <ActivityEditorContainer>
      <ActivityEditorSection>
        <ActivityEditorSectionHeader {...rest}>{headerSection()}</ActivityEditorSectionHeader>
        <ActivityEditor>
          <ActivityEditorArea>
            {editorPanel ? (
              editorPanel
            ) : (
              <Editor>
                <PreviewText dangerouslySetInnerHTML={{ __html: editorText || '' }} />
              </Editor>
            )}
          </ActivityEditorArea>
          {sneakPeakPanel}
        </ActivityEditor>
      </ActivityEditorSection>
      {buttonPanel && <ButtonWrapper>{buttonPanel}</ButtonWrapper>}
    </ActivityEditorContainer>
  );
};

export default NodeActivityEditor;

NodeActivityEditor.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number,
  headerPanel: PropTypes.object,
  headerRightPanel: PropTypes.object,
  headerText: PropTypes.string,
  editorPanel: PropTypes.object,
  editorText: PropTypes.string,
  sneakPeakPanel: PropTypes.object,
  buttonPanel: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
