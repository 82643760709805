import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NodeActivityEditor from '../../NodeActivityEditor';
import CommentsIcon from '../../../../public/assets/images/theme-2/icons/asterisk-white.svg';
import SneakPeakIcon from '../../../../public/assets/images/theme-2/icons/toolbar/visible-black.svg';
import { ViewSection, HeaderRequestFeedback, SneakPeak } from '../styles';
import { Editor, Questions, Question } from '../../NodeActivityEditor/styles';

const WrittenQuizContentTeacherPanel = ({ type, data, report }) => {
  const [hasPeaked, setHasPeaked] = useState({});

  if (!report || !data) {
    return null;
  }

  const { state: reportState } = report;
  const getAnswer = (questionId) =>
    report.QuizAnswers && report.QuizAnswers.find((answer) => answer.question_id === questionId);
  const getWorkInProgress = (questionId) => {
    const answer = getAnswer(questionId);
    return reportState === 'active' && answer && answer.draft;
  };
  const getWorkNotStarted = (questionId) => {
    const answer = getAnswer(questionId);
    return reportState === 'blank' || (answer && !answer.text);
  };

  const getContent = (questionId) => {
    const answer = getAnswer(questionId);
    if (getWorkNotStarted(questionId)) {
      return i18n.__('work_not_started');
    } else if (getWorkInProgress(questionId) && !hasPeaked[questionId]) {
      return i18n.__('work_in_progress');
    } else if (answer && answer.text) {
      return answer.text;
    }
  };

  const toggleReportComments = (answer) => {
    document.dispatchEvent(
      new CustomEvent('toggleComments', { detail: { entityId: answer.uuid, entityType: 'quiz_answer' } }),
    );
  };

  const sneakPeak = (questionId) => {
    setHasPeaked({ ...hasPeaked, [questionId]: true });
  };

  const getViewForQuestion = (question, index) => {
    const headerRightPanel = (
      <HeaderRequestFeedback onClick={() => toggleReportComments(getAnswer(question.id))} src={CommentsIcon} />
    );

    const editorPanel = (
      <Editor
        showStatusText={getWorkNotStarted(question.id) || (getWorkInProgress(question.id) && !hasPeaked[question.id])}
        dangerouslySetInnerHTML={{ __html: getContent(question.id) }}
      />
    );

    const sneakPeakPanel =
      getWorkInProgress(question.id) && !hasPeaked[question.id] ? (
        <SneakPeak onClick={() => sneakPeak(question.id)} src={SneakPeakIcon}>
          {i18n.__('sneak_peak')}
        </SneakPeak>
      ) : null;

    return (
      <NodeActivityEditor
        type={type}
        index={index}
        headerText={question.body}
        headerRightPanel={headerRightPanel}
        editorPanel={editorPanel}
        sneakPeakPanel={sneakPeakPanel}
        className="question"
      />
    );
  };

  const questions = data.QuizQuestions.map((question, index) => (
    <Question key={question.id}>{getViewForQuestion(question, index)}</Question>
  ));

  return (
    <ViewSection>
      <Questions>{questions}</Questions>
    </ViewSection>
  );
};

export default WrittenQuizContentTeacherPanel;

WrittenQuizContentTeacherPanel.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
};
