import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import ImageSelector from '../ImageSelector';
import Popup from '../Popup';
import useKeyPress from '../../hooks/useKeyPress';
import Template from './Template';
import SpinningIcon from '../../../public/assets/images/theme-2/loops-icon-white.svg';
import {
  SelectionPopup,
  PopupClose,
  SettingsPanel,
  SettingsHeader,
  PreviewPanel,
  ImageSetting,
  LinkTitle,
  LinkDescription,
  Templates,
  TemplateWrapper,
  ColumnWrapper,
  Wrapper,
  DefaultImage,
  Description,
  Placeholder,
} from './styles';

const CustomEmbed = ({ data, fileService, onSelect, onClose, isLocked, ...rest }) => {
  const { url, fileName, title, description, imageUrl, favIcon, templateId } = data;

  const supportedFileTypes = ['csv', 'doc', 'htm', 'key', 'msg', 'odt', 'pages', 'pdf', 'ppt', 'rtf', 'txt', 'xls'];

  const getFileTypeIcon = () => {
    const extensionRegexp = /(?:\.([^.]+))?$/;
    let extension = extensionRegexp.exec(fileName)[1];
    if (extension && extension.length === 4) {
      extension = extension.substring(0, 3);
    }
    const iconBasePath = '/assets/images/icons/filetypes/';
    const iconType = supportedFileTypes.includes(extension) ? extension : 'default';
    return `${iconBasePath}${iconType}.svg`;
  };
  const defaultImage = favIcon || getFileTypeIcon();

  const [linkTitle, setLinkTitle] = useState(title);
  const [linkDescription, setLinkDescription] = useState(description);
  const [linkImage, setLinkImage] = useState(imageUrl);
  const [selectedTemplate, setSelectedTemplate] = useState(templateId);
  const [popupIsOpen, setPopupIsOpen] = useState(!templateId);
  const [uploading, setUploading] = useState(false);
  const [extracted, setExtracted] = useState(false);

  useEffect(() => {
    const getFileTitle = () => {
      if (!title && fileName) {
        const fileNameRegexp = /([^/]+)(?=\.\w+$)/;
        const match = fileNameRegexp.exec(fileName);
        if (match) {
          setLinkTitle(fileNameRegexp.exec(fileName)[0]);
          setExtracted(true);
        } else {
          setLinkTitle(fileName.split('/').pop());
          setExtracted(true);
        }
      }
    };
    getFileTitle();
  }, []);

  const onUpload = (imageFile) => {
    setLinkImage(`${fileService.url}220x220/${imageFile.filename}`);
  };

  const selectDefault = () => {
    setLinkImage(defaultImage);
  };

  const selectTemplate = (ev, id) => {
    ev.preventDefault();
    setPopupIsOpen(false);
    setSelectedTemplate(id);
    onSelect(id, linkTitle, linkDescription, linkImage);
  };

  const onLinkClick = (ev) => {
    if (!isLocked()) {
      ev.preventDefault();
      setPopupIsOpen(true);
    }
  };

  const onRemove = (ev) => {
    if (!isLocked()) {
      ev.preventDefault();
      onClose(0);
    }
  };

  const handleClose = () => {
    setPopupIsOpen(false);
    setLinkTitle(title);
    setLinkDescription(description);
    setLinkImage(imageUrl);
    onClose(selectedTemplate);
  };

  useKeyPress('Escape', handleClose, popupIsOpen);

  const onLoading = (loading) => {
    setUploading(loading);
  };

  const templateOrder = [1, 3, 4, 2, 0];

  const selectionPopup = (
    <SelectionPopup className="embed-popup" {...rest}>
      <PopupClose
        tabIndex={0}
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        aria-label={i18n.__('app_close')}
      />
      <SettingsPanel>
        <ColumnWrapper>
          <SettingsHeader htmlFor="linkTitle">{i18n.__('select_template.title_header')}</SettingsHeader>
          {extracted && !templateId && <Description>{i18n.__('select_template.title_description')}</Description>}
          <LinkTitle>
            <TextField
              autoFocus
              id="linkTitle"
              name="linkTitle"
              aria-label={i18n.__('select_template.title_header')}
              placeholder={i18n.__('select_template.title_placeholder')}
              defaultValue={linkTitle}
              onChange={(ev) => setLinkTitle(ev.target.value)}
            />
          </LinkTitle>
          <SettingsHeader htmlFor="linkDescription">{i18n.__('select_template.description_header')}</SettingsHeader>
          <LinkDescription>
            <TextField
              id="linkDescription"
              name="linkDescription"
              aria-label={i18n.__('select_template.description_header')}
              placeholder={i18n.__('select_template.description_placeholder')}
              defaultValue={linkDescription}
              onChange={(ev) => setLinkDescription(ev.target.value)}
            />
          </LinkDescription>
          <ImageSetting>
            <SettingsHeader htmlFor="linkImage">{i18n.__('select_template.image_header')}</SettingsHeader>
            <Description>{i18n.__('select_template.image_description')}</Description>
            <Wrapper top="10">
              <DefaultImage
                id="linkImage"
                tabIndex={0}
                src={defaultImage}
                title={i18n.__('select_template.image_header')}
                aria-label={i18n.__('select_template.image_header')}
                className="image"
                onClick={selectDefault}
                onKeyPress={selectDefault}
              />
              <ImageSelector
                fileService={fileService}
                label={i18n.__('select_template.use_your_own')}
                onUpload={onUpload}
                onLoading={onLoading}
                whiteTheme={true}
              />
              {uploading && <Placeholder icon={SpinningIcon} />}
            </Wrapper>
          </ImageSetting>
        </ColumnWrapper>
      </SettingsPanel>
      <PreviewPanel>
        <ColumnWrapper>
          <SettingsHeader>{i18n.__('select_template.appearance_header')}</SettingsHeader>
          <Description>{i18n.__('select_template.appearance_description')}</Description>
          <Templates>
            {templateOrder.map((key) => {
              const template = `template_${key}`;
              return (
                <TemplateWrapper
                  tabIndex={-1}
                  title={i18n.__('select_template.tooltip')}
                  key={key}
                  onClick={(ev) => selectTemplate(ev, template)}
                  onKeyPress={(ev) => selectTemplate(ev, template)}
                >
                  <Template
                    id={template}
                    tabIndex={0}
                    selected={selectedTemplate === template}
                    url={url}
                    title={linkTitle}
                    description={linkDescription}
                    imageUrl={linkImage}
                    showPlaceholders={!templateId}
                    preview={true}
                  />
                </TemplateWrapper>
              );
            })}
          </Templates>
        </ColumnWrapper>
      </PreviewPanel>
    </SelectionPopup>
  );

  const contents = (
    <>
      {selectedTemplate && (
        <Template
          id={selectedTemplate}
          url={url}
          title={title}
          description={description}
          imageUrl={imageUrl}
          onLinkClick={onLinkClick}
          onRemove={onRemove}
        />
      )}
      <Popup popupIsOpen={popupIsOpen} id="embed-popup" fullBody={selectionPopup} onClose={onClose} overflowY={true} />
    </>
  );
  return contents;
};

CustomEmbed.propTypes = {
  data: PropTypes.object.isRequired,
  fileService: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLocked: PropTypes.func.isRequired,
  rest: PropTypes.object,
};

export default CustomEmbed;
